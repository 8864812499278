import { UPDATE_UI, SHOW_HOME_BANNER, REMOVE_HOME_BANNER } from '../actions/ui';

const defaultState = {};

export default (state: any = defaultState, action: any) => {
  const { type } = action;

  switch (type) {
    case UPDATE_UI:
      return { ...state, ...action.payload };
    case SHOW_HOME_BANNER:
      return { ...state, banner: { ...action.payload } };
    case REMOVE_HOME_BANNER:
      return { ...state, banner: undefined };
    default:
      return state;
  }
};
