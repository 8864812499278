import React from 'react';
import { NoSsr, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Button } from '@kiiwi/ui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button as CustomButton } from '@/components/ui/not-existing-kiiwi-components/Button';
import { useTranslation } from '@/src/i18n';
import { APP_ROUTES } from '@/src/constants';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

const { HOME, LOGIN, REGISTER } = APP_ROUTES;

type NotAuthenticatedProps = {
  isBcb: boolean;
};

const NotAuthenticated = ({ isBcb }: NotAuthenticatedProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <NoSsr>
      <div className="flex items-center gap-3">
        {/* Are you a healthcare professional? */}
        {router.pathname === HOME && (
          <a
            data-cy={isMobile ? 'btn-header-help' : 'btn-header-pro'}
            href={
              isMobile
                ? 'https://maiia-guide.zendesk.com/hc/fr'
                : 'https://suite.maiia.com/'
            }
            className="px-3 font-semibold text-content-inverse"
          >
            {t(isMobile ? 'need-help' : 'are_you_professional')}
          </a>
        )}
        {/* Authentication buttons */}
        {!isMd && (
          <Link href={REGISTER}>
            <Button size="sm" variant="secondary-inverse">
              {t('register-2')}
            </Button>
          </Link>
        )}

        <Link href={LOGIN}>
          {!isMd ? (
            <CustomButton
              size="sm"
              variant="primary-inverse"
              className={isBcb ? 'text-[#4eb3c9]' : ''}
            >
              {t('login')}
            </CustomButton>
          ) : (
            <FontAwesomeIcon
              name="circle-user:solid"
              className="size-8 cursor-pointer text-action-inverse"
            />
          )}
        </Link>
      </div>
    </NoSsr>
  );
};

export default NotAuthenticated;
