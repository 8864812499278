export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

export const enqueueSnackbar = notification => {
  const key = notification?.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      anchorOrigin: notification?.anchorOrigin || defaultAnchorOrigin,
      key: (key || new Date().getTime() + Math.random()).toString(),
    },
  };
};

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const showSnackbar = notification => ({
  type: SHOW_SNACKBAR,
  notification,
});
