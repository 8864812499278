/* eslint-disable prefer-promise-reject-errors, no-return-await */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  asyncActions,
  addressesActions,
  AddressType,
  transformAddress,
} from '@docavenue/core';
import { localitiesActions } from '../actions';

type Status = 'INITIAL' | 'READY' | 'ERROR';
type Location =
  | {
      longitude: number;
      latitude: number;
      label: string;
      type: 'street';
    }
  | {
      shortName: string;
      zipCode: string;
      label: string;
    };

export type GeoPermssionStatus = {
  geoPermission: 'rejected' | 'timeout' | 'reverse_failed';
};

type GetUserLocation = [GeoPermssionStatus | null, Location | null];

type Options = {
  shouldRun: boolean;
};

const requestGeoPermission = (): Promise<
  | {
      longitude: number;
      latitude: number;
    }
  | GeoPermssionStatus
> =>
  new Promise(resolve =>
    navigator.geolocation.getCurrentPosition(
      async ({ coords: { longitude, latitude } }) => {
        resolve({ longitude, latitude });
      },
      error => {
        // eslint-disable-next-line
        console.warn(error);
        resolve({ geoPermission: 'rejected' });
      },
    ),
  );

const delayWithCancel = ms => {
  let timeoutId;
  const promise = new Promise(resolve => {
    timeoutId = setTimeout(resolve, ms);
  });
  return {
    promise,
    cancel: () => {
      if (timeoutId) clearTimeout(timeoutId);
    },
  };
};

const REQUEST_GEO_PERMISSION_TIMEOUT = 5000;

const userAddressSelector = state =>
  state?.authentication?.item?.userPatientInformation?.userPatientProfiles?.find(
    u => u.isDefault,
  )?.address;

const geoIpLocationSelector = state => state?.geoip?.location;

const useUserLocation = (opts: Options = { shouldRun: true }) => {
  const { shouldRun } = opts;
  const [state, setState] = useState<{
    status: Status;
    error: GeoPermssionStatus | null;
    location: Location | null;
  }>({
    status: 'INITIAL',
    error: null,
    location: null,
  });
  const userAddress = useSelector(userAddressSelector);
  const geoIpLocation = useSelector(geoIpLocationSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserLocationByProfile = (): Promise<GetUserLocation> =>
      new Promise((resolve, reject) => {
        if (userAddress?.location?.coordinates)
          return resolve([
            null,
            {
              longitude: userAddress?.location?.coordinates[0],
              latitude: userAddress?.location?.coordinates[1],
              label: userAddress.fullAddress,
              type: 'street',
            },
          ]);
        return reject();
      });

    const getUserLocationByNavigator = (): Promise<GetUserLocation> =>
      new Promise((resolve, reject) => {
        if (!(typeof navigator !== 'undefined' && navigator.geolocation)) {
          return reject({ geoPermission: 'not_supported' });
        }
        // Start timeout
        const delayInstance = delayWithCancel(REQUEST_GEO_PERMISSION_TIMEOUT);
        delayInstance.promise.then(() => reject({ geoPermission: 'timeout' }));
        // Call requestPermisson
        requestGeoPermission().then(res => {
          if (delayInstance && delayInstance.cancel) delayInstance.cancel();
          if ('geoPermission' in res) {
            return reject({ geoPermission: res.geoPermission });
          }
          if (res.longitude && res.latitude) {
            const { longitude, latitude } = res;
            asyncActions<AddressType[]>(
              dispatch,
              addressesActions.reverse(longitude, latitude),
            ).then(data => {
              // Reverse address failed, or address is not in France
              if (data.length === 0) {
                return reject({ geoPermission: 'reverse_failed' });
              }
              const address = transformAddress(data[0]);
              return resolve([null, address]);
            });
          }
        });
      });

    const getUserLocationByGeoIP = async (
      prevErr: GeoPermssionStatus | null,
    ): Promise<GetUserLocation> => {
      if (geoIpLocation?.longitude) {
        const { longitude, latitude } = geoIpLocation;
        const data = await asyncActions(
          dispatch,
          addressesActions.reverse(longitude, latitude),
        );
        if (data.length === 0)
          return [{ geoPermission: 'reverse_failed' }, null];

        const { citycode } = data[0].properties;
        const locality = await asyncActions(
          dispatch,
          localitiesActions.getOne(citycode),
        );
        const { shortName, departmentCode, postCodes } = locality;
        const cityPostCodes = postCodes.filter(code =>
          code.toString().startsWith(departmentCode || ''),
        );
        const postcode = cityPostCodes.sort()[0];
        const address = {
          shortName,
          zipCode: postcode,
          label: `${shortName} (${postcode})`,
        };
        return [null, address];
      }
      if (prevErr?.geoPermission) {
        return [prevErr, null];
      }
      return [{ geoPermission: 'reverse_failed' }, null];
    };

    let isCancelled = false;

    const getUserLocation = async () => {
      const [err, userLocation] = await getUserLocationByProfile()
        .catch(async () => await getUserLocationByNavigator())
        .catch(async e => await getUserLocationByGeoIP(e));
      if (isCancelled) return;
      if (err) {
        setState({
          status: 'ERROR',
          error: err,
          location: null,
        });
        return;
      }
      if (userLocation) {
        setState({
          status: 'READY',
          location: userLocation,
          error: null,
        });
      }
    };

    if (!shouldRun) return;

    getUserLocation();

    return () => {
      isCancelled = true;
    };
  }, []);

  return state;
};
export default useUserLocation;
