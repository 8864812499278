import {
  SET_TIME_OFFSET,
  SET_PUBLIC_KEY,
} from '../actions/serverConfiguration';

const initialState = {
  timeOffset: null,
  publicKey: null,
};

const serverConfiguration = (
  state: Object = initialState,
  action: any = {},
) => {
  const { type } = action;
  switch (type) {
    case SET_TIME_OFFSET: {
      return {
        ...state,
        timeOffset:
          new Date().getTime() - new Date(action.payload?.serverTime).getTime(),
      };
    }
    case SET_PUBLIC_KEY: {
      return {
        ...state,
        publicKey: action.payload?.publicKey,
      };
    }
    default:
      return state;
  }
};

export default serverConfiguration;
