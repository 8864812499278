import React from 'react';
import clsx from 'clsx';
import { CardHcd } from '@maiia/model/generated/model/api-patient/api-patient';
import { useTranslation } from '@/src/i18n';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  picturesNbr?: number;
  type?: CardHcd['type'];
  className?: string;
  isOnline?: boolean;
  size?: string;
  source?: string;
  onClick?: React.ReactEventHandler;
  componentChipsPatientsList?: React.ReactNode;
  title?: string;
  'aria-label'?: string;
}

const Avatar: React.FC<Props> = ({
  picturesNbr = 0,
  type,
  className,
  isOnline,
  size,
  source,
  onClick,
  componentChipsPatientsList,
  title,
  'aria-label': ariaLabel,
  ...props
}) => {
  const typeAvatarDefault = type?.toLowerCase() || 'practitioner';
  const defaultAvatar = `/static/images/default_${typeAvatarDefault}.svg`;
  const { t } = useTranslation();
  return (
    <span
      aria-hidden="true"
      className={clsx(
        'avatar',
        size && `avatar-size--${size}`,
        isOnline && 'avatar--online',
        className,
      )}
      style={{
        backgroundImage: `url(${source || defaultAvatar})`,
        display: 'block',
      }}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel}
      {...props}
    >
      {picturesNbr > 0 && (
        <div className="avatar-infos">
          {t('avatar_pictures_count', { count: picturesNbr })}
        </div>
      )}
      {componentChipsPatientsList}
      {picturesNbr > 1 && (
        <div className="wrapper-dots">
          {Array(picturesNbr)
            .fill(0)
            .map((_empty, index) => (
              <span
                key={index} // eslint-disable-line react/no-array-index-key
              />
            ))}
        </div>
      )}
    </span>
  );
};

export default Avatar;
