// import { captureException } from '@sentry/nextjs';

const STETHOSCOPE = 'device_stethoscope';
const OTOSCOPE = 'device_otoscope';
const PHARMACY_OTOSCOPE = 'USB 2.0 Camera';

export type Device = {
  id: string;
  label: string;
  key: string;
};

export const getDefaultDevice = (devices: Device[]) => {
  if (devices.length <= 1) {
    return devices[0];
  }
  return devices.find(
    ({ key }) => [OTOSCOPE, STETHOSCOPE, PHARMACY_OTOSCOPE].indexOf(key) < 0,
  );
};

const onMicrophoneGranted = stream => {
  // Create an AudioNode from the stream.
  window.AudioContext =
    window.AudioContext || (window as any).webkitAudioContext;
  const audioContext = new AudioContext();
  const source = audioContext.createMediaStreamSource(stream);
  const gainNode = audioContext.createGain();

  const destination = audioContext.createMediaStreamDestination();

  source.connect(gainNode);
  gainNode.connect(destination);
  return [gainNode, destination.stream.getAudioTracks()[0]];
};

export const amplifyStream = deviceId =>
  navigator.mediaDevices
    .getUserMedia({
      audio: {
        deviceId,
      },
    })
    .then(audio => onMicrophoneGranted(audio));

// Helper function to get detail info of video devices. ex: facingMode, resolution, framerate...
export const getDetailVideoDevices = async () => {
  let devices = await navigator.mediaDevices.enumerateDevices();
  const videoDeviceLength = devices.reduce(
    (total, d) => total + (d.kind === 'videoinput' ? 1 : 0),
    0,
  );
  if (videoDeviceLength === 0) {
    return {};
  }
  if (videoDeviceLength === 1) {
    // We mostly has only one camera, so we treat this is front camera
    const videoInputDevice = devices.find(
      d => d.kind === 'videoinput',
    ) as MediaDeviceInfo;
    return {
      [videoInputDevice?.deviceId]: {
        ...videoInputDevice,
        facingMode: 'user',
      },
    };
  }

  await navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(stream => stream.getTracks().forEach(track => track.stop()))
    .catch(() => {});
  devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices
    .filter(device => device.kind === 'videoinput')
    .map(deviceInfo => deviceInfo.toJSON());
  const videoDevicesById = videoDevices.reduce(
    (current, device) => ({ ...current, [device.deviceId]: device }),
    {},
  );
  const promises: Promise<
    Record<string, MediaDeviceInfo & MediaTrackCapabilities>
  >[] = [];
  for (const d of videoDevices) {
    promises.push(
      navigator.mediaDevices
        .getUserMedia({ video: { deviceId: d.deviceId } })
        // eslint-disable-next-line no-loop-func
        .then(stream => {
          let devicesInfo: Record<
            string,
            MediaDeviceInfo & MediaTrackCapabilities
          > = {};
          stream.getVideoTracks().forEach(track => {
            const capabilities = track.getCapabilities();
            const { deviceId } = capabilities;
            if (!deviceId) {
              track.stop();
              return;
            }
            devicesInfo = {
              ...devicesInfo,
              [deviceId]: {
                ...videoDevicesById[deviceId],
                ...devicesInfo[deviceId],
                ...capabilities,
              },
            };
            track.stop();
          });
          return Promise.resolve(devicesInfo);
        })
        .catch(() => ({})),
    );
  }
  const detailVideoDevices = (await Promise.all(promises)).reduce(
    (curr, next) => ({ ...curr, ...next }),
    {},
  );
  return detailVideoDevices;
};

/** Test filter
export const makeDistortionCurve = (amount) => {
  var k = typeof amount === 'number' ? amount : 50,
    n_samples = 44100,
    curve = new Float32Array(n_samples),
    deg = Math.PI / 180,
    i = 0,
    x;
  for (; i < n_samples; ++i) {
    x = (i * 2) / n_samples - 1;
    curve[i] = ((3 + k) * x * 20 * deg) / (Math.PI + k * Math.abs(x));
  }
  return curve;
}
*/
// eslint-disable-next-line unused-imports/no-unused-vars-ts
class UserReportTLCError extends Error {
  name: string;

  constructor(message: string, name?: string) {
    super(message);
    this.name = name || 'MAIIA_USER_REPORT_TLC_ERROR';
    this.stack = new Error(message).stack;
  }
}

export const collectUserInformation = async ctx => {
  const browserCompactible = (window as any).OT.checkSystemRequirements();
  const mediaDevices = await navigator.mediaDevices
    .enumerateDevices()
    .then(devices =>
      devices.map(
        device => `${device.kind}: ${device.label} id = ${device.deviceId}`,
      ),
    )
    .catch(e => e.toString());
  const cameraPermission = await navigator.permissions
    .query({ name: 'camera' as PermissionName })
    .catch(() => ({ state: 'unknown' }));
  const microphonePermission = await navigator.permissions
    .query({ name: 'microphone' as PermissionName })
    .catch(() => ({ state: 'unknown' }));

  return {
    browserCompactible,
    mediaDevices,
    clientType: 'PATIENT',
    otSession: ctx?.session?.current?.session?.id,
    cameraPermission: cameraPermission.state,
    microphonePermission: microphonePermission.state,
  };
};

// todo allow to activate the function bellow via env var

// eslint-disable-next-line unused-imports/no-unused-vars-ts
export const reportBugTlc = async info => {
  // captureException(new UserReportTLCError(info.title), {
  //   tags: {
  //     scope: 'TLC_REPORT',
  //   },
  //   extra: {
  //     ...info,
  //   },
  // });
};
