import * as React from 'react';
import { css, styled } from '@mui/material/styles';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  NoSsr,
  Theme as MUITheme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IconChevronDown, List } from '@maiia/design-system';
import { useQuery } from '@tanstack/react-query';
import { Separator } from '@kiiwi/ui';
import { useTranslation } from '@/src/i18n';
import { dynamicFooterTlc } from '../footerMenus';
import { getArticleBySlug } from '@/src/hooks/articles/fetchers';
import { dynamicFooterArticlesList } from './utils';

/* JS styling */

const FooterAccordion = styled(Accordion)(
  ({ theme }) => css`
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    color: ${theme.palette.common.white};

    &:last-of-type {
      border-radius: 0;
    }

    .MuiButtonBase-root {
      padding-left: 0;
      padding-right: 0;

      .MuiAccordionSummary-content {
        margin-top: ${theme.spacing(3)};
        margin-bottom: ${theme.spacing(3)};

        h2 {
          margin-bottom: 0;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          width: auto;
          height: ${theme.spacing(3)};
          fill: ${theme.palette.common.white};
        }
      }
    }

    .MuiAccordionDetails-root {
      padding: 0 0 ${theme.spacing(3)} 0;
    }
  `,
);

const ListBold = styled(List)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(3)};

    li {
      font-weight: 700;

      &:not(:first-of-type) {
        margin-top: ${theme.spacing(2)};
      }

      &.with-disc {
        font-weight: 400;
        list-style-type: disc;

        margin-left: ${theme.spacing(3)};
        margin-top: ${theme.spacing(0.5)};
      }
    }
  `,
);

const DynamicFooterTlc = () => {
  const { t } = useTranslation();
  const listOne = dynamicFooterTlc.listOne(t);
  const listTwo = dynamicFooterTlc.listTwo(t);
  const isMobile = useMediaQuery<MUITheme>(theme =>
    theme.breakpoints.down('sm'),
  );
  const { data: indexArticle } = useQuery(['teleconsultation-articles'], () =>
    getArticleBySlug('teleconsultation', {
      withFullHierarchy: true,
      depth: 3,
    }),
  );

  const accordionTitle = (
    <Typography variant="h3" component="h2">
      {t('footer_informations_and_articles')}
    </Typography>
  );
  const accordionContent = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <List listItems={listOne} className="with-disc" />
        {isMobile && <Separator className="mt-6 bg-surface-white" />}
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <List listItems={listTwo} />

        {indexArticle && (
          <ListBold
            listItems={dynamicFooterArticlesList(indexArticle, !isMobile)}
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    <NoSsr>
      {isMobile ? (
        <>
          <Separator className="bg-surface-white" />
          <FooterAccordion>
            <AccordionSummary expandIcon={<IconChevronDown />}>
              {accordionTitle}
            </AccordionSummary>
            <AccordionDetails>{accordionContent}</AccordionDetails>
          </FooterAccordion>
        </>
      ) : (
        <>
          {accordionTitle}
          {accordionContent}
        </>
      )}
    </NoSsr>
  );
};

export default DynamicFooterTlc;
