import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import {
  ANNOUNCEMENT,
  HEALTH_CHECK_FRONT_PATIENT,
  HEALTH_CHECK_FRONT_PATIENT_ENCRYPTION,
  fetchRetry,
} from '@docavenue/core';
import config from '@/src/config';

/**
 * Calls the healthchecks of the patient + encrypted patient api, display a
 * maintenance message if they're down. https://jirachs.cegedim.com/browse/MPAT-3003
 * @returns null
 */
const HealthcheckDetector = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const serviceToChecks = [
      { service: HEALTH_CHECK_FRONT_PATIENT, channel: 'maintenance' },
    ];
    if (
      config.get('ENCRYPTION') === 'true' ||
      config.get('DISABLE_ENCRYPTION') === 'true'
    ) {
      // Force encrypt or force disable encrypt
      dispatch({
        type: ANNOUNCEMENT,
        payload: {
          service: HEALTH_CHECK_FRONT_PATIENT_ENCRYPTION,
          channel: 'encryption',
          status: config.get('ENCRYPTION') === 'true',
        },
      });
    } else {
      serviceToChecks.push({
        service: HEALTH_CHECK_FRONT_PATIENT_ENCRYPTION,
        channel: 'encryption',
      });
    }
    const run = async () => {
      const promises: Promise<string | object>[] = serviceToChecks.map(
        service =>
          fetchRetry(
            fetch,
            `${config.get('API_ENDPOINT')}/healthcheck/status/${
              service.service
            }`,
            1000,
            30,
            {},
            true,
          )
            .then(res => res.text())
            .catch(() => 'ko')
            .then(res => {
              dispatch({
                type: ANNOUNCEMENT,
                payload: {
                  service: service.service,
                  channel: service.channel,
                  status: res === 'ok',
                },
              });
            }),
      );

      await Promise.all(promises);
    };
    run();
  }, []);

  return null;
};
export default HealthcheckDetector;
