import { applyMiddleware, createStore, Store, AnyAction } from 'redux';
import createSagaMiddleware, { Task } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { createWrapper } from 'next-redux-wrapper';
import { crudSaga, wsSaga } from '@docavenue/core';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rest from './rest';
import config from './config';
import reducer from './reducers';
import watchInClearSession from './sagas/watchInClearSession';
import snackbarSaga from './sagas/snackbar';
import wsPatientSaga from './sagas/ws';
import logoutSaga from './sagas/logout';

export interface SagaStore extends Store {
  sagaTask: Task;
}

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require,import/no-extraneous-dependencies
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const persistConfig = {
  key: 'maiia',
  storage,
  whitelist: ['temporaryAppointments', 'ui'], // place to select which state you want to persist
};

const persistedReducer = persistReducer(persistConfig, reducer);

/**
 * This variable contains the instance of the store so that `getToken` can access it via the closure.
 * DO NOT EXPOSE IT
 * It is only assigned when this code runs in the browser.
 * When this code runs in the server, it will remain `null` to ensure the store will never be shared in any way.
 */
let browserOnlyAvailableStoreInstanceViaClosure: Store<
  any,
  AnyAction
> | null = null;

// eslint-disable-next-line unused-imports/no-unused-vars-ts
export function makeStore(context) {
  const state = {};
  const isServer = typeof window === 'undefined';
  const sagaMiddleware = createSagaMiddleware();
  const websocketMiddleware = createSagaMiddleware();
  const _reducer = isServer ? reducer : persistedReducer;
  const store = createStore(
    _reducer,
    state,
    bindMiddleware([sagaMiddleware, websocketMiddleware]),
  );
  (store as any).__persistor = isServer ? undefined : persistStore(store);

  const saga = function* rootSaga() {
    yield all(
      [crudSaga(rest), watchInClearSession, snackbarSaga, logoutSaga].map(fork),
    );
  };

  (store as any).sagaTask = sagaMiddleware.run(saga);
  if (!isServer) {
    const browserSagas = function* browserSagas() {
      yield all(
        [
          wsSaga(config.get('WEBSOCKET_URI'), {
            appointment: {
              resource: 'appointments',
              params: {
                aggregateWith: 'patient,consultationReason',
              },
            },
            videoSession: {
              resource: 'videoSessions',
              chunkUrlResource: 'video-sessions',
              params: {
                aggregateWith: 'cardHcd',
              },
            },
            document: {
              resource: 'documents',
              chunkUrlResource: 'documents',
              params: {
                aggregateWith: 'patient,practitioner,center',
              },
            },
          }),
          wsPatientSaga,
        ].map(fork),
      );
    };

    websocketMiddleware.run(browserSagas);
    (store as any).addSaga = sagaFn => sagaMiddleware.run(sagaFn);
  }

  if (typeof window !== 'undefined' && (window as any).Cypress) {
    (window as any).__store__ = store;
  }

  if (typeof window !== 'undefined') {
    browserOnlyAvailableStoreInstanceViaClosure = store;
  }

  return store as SagaStore;
}

// https://github.com/kirill-konshin/next-redux-wrapper/issues/276#issuecomment-891342315
// Error serializing `.initialState.authentication.error` returned from `getServerSideProps` in "/".
export const wrapper = createWrapper<SagaStore>(makeStore, {
  debug: false,
  serializeState: state => JSON.stringify(state),
  deserializeState: state => JSON.parse(state),
});

// only used browser-side - token doesn't leak between server requests
export const getToken = (url?: string) => {
  const scope = url?.split('/').filter(Boolean)[0] || ''; // url could start with leading slash (or not)
  return (
    typeof window !== 'undefined' &&
    typeof url === 'string' &&
    !scope.startsWith('pat-public') && // don't pass token when targeting /pat-public
    browserOnlyAvailableStoreInstanceViaClosure?.getState()?.authentication
      ?.item?.token
  );
};

/**
 * Retrieve encryption infos just like redux-saga would.
 * See `packages/core/src/sagas/sagas.ts` for original implementation.
 */
export const getEncryptionInfos = (
  url?: string,
):
  | {
      aesFileEncryptionKey?: string;
      ivFileEncryptionKey?: string;
      encryption: boolean;
      publicKey: string | null;
    }
  | undefined => {
  const scope = url?.split('/').filter(Boolean)[0] || ''; // url could start with leading slash (or not)
  if (
    typeof window !== 'undefined' &&
    typeof url === 'string' &&
    !scope?.startsWith('pat-public')
  ) {
    const state = browserOnlyAvailableStoreInstanceViaClosure?.getState();
    return {
      // or let `encryptionService.storage.getItem('keyId')` do the job
      aesFileEncryptionKey:
        state?.authentication?.item?.aesKey /* pat */ ||
        state?.users?.item?.aesKey /* mobile-pat */,
      ivFileEncryptionKey:
        state?.authentication?.item?.iv /* pat */ ||
        state?.users?.item?.iv /* mobile-pat */,
      encryption: state?.announcements?.encryption || false,
      publicKey: state?.serverConfiguration.publicKey || null,
    };
  }
};
