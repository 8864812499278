import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { actionsGeneratorV2, asyncActions } from '@docavenue/core';
import { AmcBrandingDTO } from '@maiia/model/generated/model/api-patient/api-patient';
import { useCurrentUser } from '@docavenue/components';
import { getPicture } from '@/components/utils/card';
import { PatientUser } from '../WithAuth/types';

const amcBrandingActions = actionsGeneratorV2({
  resource: 'amcBranding',
  chunkUrlResource: 'amc-branding',
  scope: 'pat',
});

const InsuranceNavLinks = ({ toggleDrawer }) => {
  const currentUser = useCurrentUser<PatientUser>();
  const dispatch = useDispatch();
  const { data } = useQuery<AmcBrandingDTO[]>(
    ['amcBranding', `user:${currentUser?.id}`],
    () =>
      asyncActions(dispatch, amcBrandingActions.getList()).then(
        res => res?.items ?? [],
      ),
    { enabled: Boolean(currentUser) },
  );

  if (!data) return null;

  const filteredAmcs = data.filter(
    amc => amc.website && amc.mainPicture?.thumbnailS3Id,
  );

  return (
    <>
      {filteredAmcs.map(amc => (
        <a
          key={amc.name}
          href={amc.website}
          className="nav-link"
          onClick={toggleDrawer}
          target="_blank"
          rel="noreferrer"
        >
          <figure className="amc-branding-container">
            <img
              src={getPicture(amc.mainPicture?.thumbnailS3Id)}
              alt={`${amc.name} logo`}
            />
          </figure>

          <p>{amc.name}</p>
        </a>
      ))}
    </>
  );
};

export default InsuranceNavLinks;
