/* eslint-disable import/prefer-default-export */
import { QueryClient, QueryObserverOptions } from '@tanstack/react-query';

import {
  makeQueryFn,
  ApiClientFactory,
} from '@maiia/model/generated/core/react-query';

type MakeQueryClientRequireOptions = {
  getApiClient: ApiClientFactory;
};

export function makeQueryClient(
  { getApiClient }: MakeQueryClientRequireOptions,
  options: QueryObserverOptions = {},
) {
  return new QueryClient({
    defaultOptions: {
      queries: {
        ...(['development', 'test'].includes(process.env.NODE_ENV) && {
          retry: false,
        }), // disable failing query retries on development and test environments: https://tanstack.com/query/v4/docs/framework/react/guides/query-retries
        refetchOnWindowFocus: process.env.NODE_ENV === 'production',
        queryFn: makeQueryFn({ getApiClient }),
        ...options,
      },
    },
  });
}
