import React from 'react';
import { useRouter } from 'next/router';
import { APP_ROUTES } from '../constants';

// if we change this, see also tarteaucitronlauncher.js
const SESSION_STORAGE_KEY = 'isBcbWebview';

type Props = {
  enableRedirection?: boolean;
  redirectionUrl?: string;
};

const isRouteAllowed = (route: string) => {
  return (
    route.startsWith(APP_ROUTES.MEDICAMENTS.BASE_URL) ||
    route.startsWith(APP_ROUTES.MEDICAMENTS.INDEX)
  );
};

// A webview for the BCB pages is used in the mobile application
// This hook is used to restrict the navigation
// If redirection is not enable, it will only abort the navigation
const useBcbWebview = (props: Props = {}) => {
  const {
    enableRedirection = true,
    redirectionUrl = props.redirectionUrl || APP_ROUTES.MEDICAMENTS.BASE_URL,
  } = props;

  const router = useRouter();
  const [isActive, setIsActive] = React.useState(false);
  const [shouldRedirect, setShouldRedirect] = React.useState(
    !isRouteAllowed(router.asPath),
  );

  // handle redirection if enabled
  React.useEffect(() => {
    if (isActive && enableRedirection && shouldRedirect) {
      router.push(redirectionUrl);
      setShouldRedirect(false);
    }
  }, [enableRedirection, isActive, redirectionUrl, router, shouldRedirect]);

  // Check if bcbWebview is active in sessionStorage and in query
  // Add listener if a navigation is not allowed
  React.useEffect(() => {
    const onRouteChangeStart = (url: string) => {
      if (!isRouteAllowed(url)) {
        setShouldRedirect(true);
        // throw an error to prevent nextjs navigation
        // https://github.com/vercel/next.js/discussions/12348
        // https://github.com/zeit/next.js/issues/2476
        throw new Error('BCB routeChange aborted.');
      }
      return true;
    };

    const checkIsActive =
      sessionStorage.getItem(SESSION_STORAGE_KEY) === 'true' ||
      router.query[SESSION_STORAGE_KEY] === 'true';
    if (checkIsActive) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
      router.events.on('routeChangeStart', onRouteChangeStart);
      setIsActive(true);
    } else {
      setIsActive(false);
    }

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [router]);

  return {
    isActive,
    isRedirectionEnabled: enableRedirection,
  };
};

export default useBcbWebview;
