import React from 'react';
import Head from 'next/head';

const customCSS = `
  body::before {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: white;
  }
  .render body::before {
    display: none;
  }
`;
const noscriptCSS = `
  body::before {
    content: none
  }
`;

const NoPageFlicker = () => (
  <Head>
    <style
      // eslint-disable-next-line react/no-danger,local-rules/no-unsafe-dangerously-set-inner-html
      dangerouslySetInnerHTML={{ __html: customCSS }}
    />
    <noscript>
      <style
        // eslint-disable-next-line react/no-danger,local-rules/no-unsafe-dangerously-set-inner-html
        dangerouslySetInnerHTML={{ __html: noscriptCSS }}
      />
    </noscript>
  </Head>
);

export default NoPageFlicker;
