import { useDispatch } from 'react-redux';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Dispatch } from 'redux';
import { asyncActions } from '@docavenue/core';
import queryKeys from './queryKeys';
import { BrandingInformations } from './types';
import { brandingsActions } from '@/src/actions';
import { couldBeAPharmacy } from '@/src/utils';

const getBranding = (
  dispatch: Dispatch,
  subdomain: string,
): Promise<BrandingInformations> | null =>
  asyncActions(dispatch, brandingsActions.getOne(subdomain));

const useBranding = (
  options?: UseQueryOptions<unknown, unknown, BrandingInformations>,
) => {
  const dispatch = useDispatch();
  const clientSubdomain =
    typeof window === 'undefined'
      ? undefined
      : window.location.hostname.split('.')[0];

  const queryResult = useQuery({
    queryKey: queryKeys.getBranding(clientSubdomain),
    // we are sure that clientSubdomain is a string because the enabled protects it
    queryFn: () => getBranding(dispatch, clientSubdomain as string),
    retry: 0,
    enabled: clientSubdomain && couldBeAPharmacy(clientSubdomain),
    ...options,
  });
  return {
    ...queryResult,
    /**
     * If the query is completed, then having data in the result means that we
     * are on a branded subdomain.
     *
     * If the query is not over yet, we show an optimistic result as quickly as
     * possible based on the hostname.
     */
    isBrandedSubdomain: queryResult.isFetched
      ? Boolean(queryResult.data)
      : couldBeAPharmacy(clientSubdomain),
  };
};

export default useBranding;
