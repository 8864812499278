import { CustomPage } from '@/types/page.types';
import { PatientUser, Voter } from './types';

const isLoggedin = (user: PatientUser) => {
  if (user) return true;
  return '/login';
};

const isVerified = ({ isConfirmed: profileIsConfirmed }: PatientUser) => {
  if (profileIsConfirmed) {
    return true;
  }
  return '/sms-confirmation';
};

const isCreatedProfile = ({
  userPatientInformation: { userPatientProfiles },
}: PatientUser) => {
  if (userPatientProfiles?.length === 0) {
    return '/profile-creation';
  }

  return true;
};

/*
 * Helper function for check status of account
 * We have 3 level for check logged_in -> verified -> created_profile
 * */
// eslint-disable-next-line import/prefer-default-export
export const isCompleted = (
  user: PatientUser,
  authConfig: true | CustomPage['authenticated'],
) => {
  const voters: Voter[] = [
    {
      name: 'loggedin',
      func: isLoggedin,
    },
    {
      name: 'verified',
      func: isVerified,
    },
    {
      name: 'createdProfile',
      func: isCreatedProfile,
    },
  ];

  const levelUpVoterIsPresent = (voter: Voter): boolean => {
    const voterIdx = voters.indexOf(voter);
    if (voterIdx === -1) return false;
    for (let i = voterIdx + 1; i < voters.length; i++) {
      if (authConfig?.[voters[i].name] === true) return true;
    }
    return false;
  };

  for (const voter of voters) {
    if (
      authConfig === true ||
      authConfig?.[voter.name] === true ||
      levelUpVoterIsPresent(voter)
    ) {
      const vote = voter.func(user);
      if (typeof vote === 'string') {
        return vote;
      }
    }
  }

  return true;
};

// export const hasAuthCookie = () =>
//   document.cookie?.indexOf('isAuthenticated') !== -1;

export const redirectWithNextParam = (newUrl: string) => {
  if (typeof window === 'undefined') return;
  const url = new URL(newUrl, window.location.href);
  url.searchParams.append(
    'next',
    window.location.pathname + window.location.search,
  );
  window.location.replace(url.toString());
};
