import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { CardHcd } from '@maiia/model/generated/model/api-patient/api-patient';
import { asyncActions } from '@docavenue/core';
import { useDispatch, useSelector } from 'react-redux';
import useAsyncError from './useAsyncError';
import { closestAvailabilitiesActions } from '../actions';
import useIsPrescriber from './useIsPrescriber';
import { suggestionItemSelector } from '../selectors';
import {
  CENTER,
  CONSULTATION_REASON,
  PHYSICAL,
  TELECONSULTATION,
} from '../constants';

export type AvailabilityType =
  | 'ALL'
  | typeof PHYSICAL
  | typeof TELECONSULTATION;

type UseFirstAvailabilityOptions = {
  forceFetch: boolean;
};

const settingNameByType = {
  PHYSICAL: 'firstPhysicalFreeTimeslot',
  TELECONSULTATION: 'firstTeleconsultationFreeTimeslot',
};

const closestNameByType = {
  PHYSICAL: 'closestPhysicalAvailability',
  TELECONSULTATION: 'closestTeleconsultationAvailability',
};

export const getFirstAvailability = (item: CardHcd, type: AvailabilityType) => {
  if (type !== 'ALL') {
    const timeSlot = item?.settings?.[settingNameByType[type]];
    return timeSlot;
  }
  const availabilities = [
    item?.settings?.firstPhysicalFreeTimeslot,
    item?.settings?.firstTeleconsultationFreeTimeslot,
  ]
    .filter(availability => availability !== undefined)
    .map(date => dayjs(date));
  return availabilities.length ? dayjs.min(availabilities).toISOString() : null;
};
const useFirstAvailability = (
  card: CardHcd,
  type: AvailabilityType,
  opts: UseFirstAvailabilityOptions = { forceFetch: false },
) => {
  const { forceFetch } = opts;
  const dispatch = useDispatch();
  const isPrescriber = useIsPrescriber();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const throwError = useAsyncError();
  const [firstAvailability, setFirstAvailability] = useState(() =>
    getFirstAvailability(card, type),
  );
  const fetchedClosestData = useRef(false);
  const suggestion = useSelector(suggestionItemSelector);
  useEffect(() => {
    if (fetchedClosestData.current || !card?.settings?.clientDocavenue) return; // We can safely ignore force fetch
    const shouldFetchData =
      // if force fetch or
      forceFetch ||
      // not data about first availability or
      !firstAvailability ||
      // data is outdated
      !dayjs(firstAvailability).isSameOrAfter(dayjs(), 'day');
    if (!shouldFetchData) return;
    let isCancelled = false;

    const specialityFilter =
      suggestion.objectType === 'speciality' &&
      suggestion.shortName !== 'pharmacie' &&
      card.type === CENTER
        ? { specialityId: suggestion.id }
        : {};

    const expertiseFilter =
      // eslint-disable-next-line no-nested-ternary
      suggestion.objectType === 'expertise'
        ? suggestion.type?.name === CONSULTATION_REASON
          ? { consultationReasonLabel: suggestion.name }
          : { expertiseId: suggestion.id }
        : {};

    const loadData = async () => {
      const data = await asyncActions(
        dispatch,
        closestAvailabilitiesActions.getActions(isPrescriber).getList({
          practitionerId: card?.professional?.practitionerId,
          centerId: card?.center?.id,
          from: dayjs()
            .add(1, 'minute')
            .startOf('minute')
            .toISOString(),
          ...specialityFilter,
          ...expertiseFilter,
        }),
      );
      if (isCancelled) return;

      fetchedClosestData.current = true;
      let availability: string | null = null;
      if (type !== 'ALL') {
        availability = data?.[closestNameByType[type]]?.startDateTime;
      } else {
        const {
          closestPhysicalAvailability,
          closestTeleconsultationAvailability,
        } = data;
        const firstDate = [
          closestPhysicalAvailability?.startDateTime,
          closestTeleconsultationAvailability?.startDateTime,
        ]
          .filter(date => date !== undefined)
          .map(date => dayjs(date));
        availability = firstDate.length
          ? dayjs.min(firstDate).toISOString()
          : null;
      }
      setFirstAvailability(availability);
    };

    loadData().catch(throwError);
    return () => {
      isCancelled = true;
    };
  }, [card, firstAvailability, suggestion]);

  return firstAvailability;
};

export default useFirstAvailability;
