import React, { FC } from 'react';

import { useCurrentUser } from '@docavenue/components';
import { Button, DialogContent, Link, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { actionsGenerator, asyncActions } from '@docavenue/core';
import Dialog from '@/components/molecules/Dialog/Dialog';
import { useTranslation, Trans } from '@/src/i18n';
import {
  authActions,
  logoutActions,
  temporaryAppointmentsActions,
  usersActions,
} from '@/src/actions';
import { APP_ROUTES } from '@/src/constants';
import { isCompleted } from '@/components/molecules/WithAuth/functions';
import { PatientUser } from '@/components/molecules/WithAuth/types';
import useSSOUserData from '@/src/hooks/useSSOUserData';

const acceptPolicyActions = actionsGenerator({
  resource: 'privacyPolicy',
  chunkUrlResource: 'privacy-policy/accept',
});

const PrivacyPolicyPopup: FC = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser<PatientUser>();
  const router = useRouter();
  const { data: ssoData } = useSSOUserData();

  /**
   * If the user doesn't agree to the new policy, we simply log him out.
   * He is not allowed to use Maiia.
   */
  const handleLeaveMaiia = async () => {
    await asyncActions(dispatch, logoutActions.create());
    dispatch(authActions.reset());
    dispatch(temporaryAppointmentsActions.resetItem());
    router.reload();
  };

  const handleAgreePrivacyPolicy = async () => {
    await asyncActions(dispatch, acceptPolicyActions.create());
    const user = await asyncActions(dispatch, usersActions.getOne('me'));
    dispatch(authActions.setItem(user));
  };

  const isPolicyPage = Object.values(APP_ROUTES.POLICIES).includes(
    router.pathname,
  );

  const isInCypress = typeof window !== 'undefined' && (window as any).Cypress;
  return (
    <>
      {children}
      {currentUser &&
        isCompleted(currentUser, true) === true &&
        // Dont show this popup when not finished sso flow
        !ssoData &&
        /**
         * the login endpoint doesn't return isPrivacyPolicyAccepted, which
         * makes it undefined (=> false when casted) every time a user logs in.
         * It makes the popup appear in a flash while we're refetching /user/me.
         * Until the backend is fixed, we check for false to avoid this false positive.
         */
        currentUser.isPrivacyPolicyAccepted === false &&
        !isPolicyPage &&
        !isInCypress && (
          <Dialog open title={t('title-privacy-policy-updated')}>
            <DialogContent>
              <Trans
                i18nKey="we-ve-updated-the-privacy-policy-please-agree-to-use-maiia"
                components={[
                  <Link key="link1" href={APP_ROUTES.POLICIES.CGUS} />,
                ]}
              />
              <Stack
                spacing={2}
                direction="row"
                display="flex"
                justifyContent="right"
                marginTop={2}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleLeaveMaiia}
                  data-testid="leave-maiia-button"
                >
                  {t('leave-maiia')}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleAgreePrivacyPolicy}
                  data-testid="agree-button"
                >
                  {t('i-agree')}
                </Button>
              </Stack>
            </DialogContent>
          </Dialog>
        )}
    </>
  );
};

export default PrivacyPolicyPopup;
