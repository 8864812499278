import { actionsGenerator } from '@docavenue/core';

const closestAvailabilitiesActions = {
  getActions: (isPrescriber: boolean = false) =>
    actionsGenerator({
      resource: 'closestAvailabilities',
      chunkUrlResource: 'availability-closests',
      scope: isPrescriber ? 'pat' : 'pat-public',
    }),
};

export default closestAvailabilitiesActions;
