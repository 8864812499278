import { cn, twVariants } from '@kiiwi/ui';
import React, { forwardRef, HTMLAttributes } from 'react';
import { VariantProps } from '@/components/ui/not-existing-kiiwi-components/utils';

const tagStyles = twVariants({
  base: 'px-2 py-0.5 cursor-default font-body font-semibold rounded truncate',
  variants: {
    size: {
      mobile: 'min-w-5 h-5 text-body-3',
      desktop: 'min-w-6 h-6 text-body-2',
    },
    variant: {
      default: 'bg-[#EFF4F6] text-[#294B57]', // bg-picker-light-grey text-picker-grey
      info: 'bg-[#E2F4FD] text-[#1B5C7C]',
      warning: 'bg-[#FBE5CB] text-[#8E4C00]',
      success: 'bg-[#E1F9E9] text-[#00581E]',
      error: 'bg-[#FDE2E2] text-[#8E0505]',
      pink: 'bg-[#FDE2F2] text-[#7C1B55]',
      yellow: 'bg-[#FDF7E2] text-[#7C671B]',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type TagProps = HTMLAttributes<HTMLSpanElement> &
  VariantProps<typeof tagStyles> & {
    asChild?: boolean;
    children?: React.ReactNode;
  };

const Tag = forwardRef<HTMLSpanElement, TagProps>(
  (
    { className, variant = 'default', size = 'desktop', children, ...props },
    ref,
  ) => {
    return (
      <span
        className={cn(tagStyles({ variant, size }), className)}
        ref={ref}
        {...props}
      >
        {children}
      </span>
    );
  },
);

Tag.displayName = 'Tag';

export { Tag, tagStyles };
