const oldRoute = {
  '/[speciality]/[locality]': 'search',
  '/teleconsultation/sans-rdv/recherche/[[...slug]]': 'tlcList',
  '/search/suggestions': 'searchSuggestions',
  '/search/addresses': 'searchAddresses',
  '/[speciality]/[locality]/[slug]': 'hcd',
  '/[speciality]/[locality]/[slug]/rdv': 'hcdRDV',
  '/appointment/[id]': 'appointmentDetails',
  '/appointment-confirmation': 'appointmentConfirmation',
  '/sms-confirmation': 'smsConfirmation',
  '/profile-creation': 'profileCreation',
};

const getRouteName = pathname => {
  const oldName = oldRoute[pathname];
  if (oldName) return oldName;
  const cleanedPath = pathname.replace('/', '');
  return cleanedPath || 'index';
};

export default getRouteName;
