export const SET_TEMPORARY_APPOINTMENT = 'SET_TEMPORARY_APPOINTMENT';
export const RESET_TEMPORARY_APPOINTMENT = 'RESET_TEMPORARY_APPOINTMENT';
export const MERGE_TEMPORARY_APPOINTMENT = 'MERGE_TEMPORARY_APPOINTMENT';

const temporaryAppointmentsActions = {
  setItem: item => ({
    type: SET_TEMPORARY_APPOINTMENT,
    payload: {
      item,
    },
  }),
  mergeItem: item => ({
    type: MERGE_TEMPORARY_APPOINTMENT,
    payload: {
      item,
    },
  }),
  resetItem: () => ({
    type: RESET_TEMPORARY_APPOINTMENT,
  }),
};

export default temporaryAppointmentsActions;
