import { delay, put, takeEvery, race, take } from 'redux-saga/effects';
import {
  SHOW_SNACKBAR,
  REMOVE_SNACKBAR,
  removeSnackbar,
} from '../actions/snackbar';

function* snackbarHandle(action) {
  const {
    notification: { key, duration = 5000 },
  } = action;
  const { timeout } = yield race({
    timeout: delay(duration),
    canceled: take(
      cancelAction =>
        cancelAction.type === REMOVE_SNACKBAR && cancelAction.key === key,
    ),
  });
  if (timeout) yield put(removeSnackbar(key));
}

export default function* snackbar() {
  yield takeEvery(SHOW_SNACKBAR, snackbarHandle);
}
