export const SET_LOCATION = 'SET_LOCATION';

const geoipActions = {
  setLocation: location => ({
    type: SET_LOCATION,
    payload: { location },
  }),
};

export default geoipActions;
