import useMediaQuery, { Options } from '@mui/material/useMediaQuery';
import theme from '../theme';

export const IS_XS = 'IS_XS'; // Min 320px/Max 767px for small mobile phones
export const IS_SM = 'IS_SM'; // Min 768px/Max 1023px for small tablets
export const IS_MD = 'IS_MD'; // Min 1024px/Max 1279px for small desktop and phablets
export const IS_LG = 'IS_LG'; // Min 1280px for default desktop and wider
export const IS_PHONE_OR_TABLET = 'IS_PHONE_OR_TABLET'; // Between 320px and 1023px, for tablet and phone (mobile devices)

type QueryType =
  | typeof IS_XS
  | typeof IS_SM
  | typeof IS_MD
  | typeof IS_LG
  | typeof IS_PHONE_OR_TABLET;

const getBreakpoint = (type: QueryType) => {
  switch (type) {
    case IS_XS:
      return theme.breakpoints.between('xs', 'sm');
    case IS_SM:
      return theme.breakpoints.between('sm', 'md');
    case IS_MD:
      return theme.breakpoints.between('md', 'lg');
    case IS_LG:
      return theme.breakpoints.up('lg');
    case IS_PHONE_OR_TABLET:
      return theme.breakpoints.between('xs', 'md');
    default:
      return theme.breakpoints.up('lg');
  }
};

export const useMediaQueryBreakPoint = (
  type: QueryType,
  opts: Options = { noSsr: true },
) => useMediaQuery(getBreakpoint(type), opts);
