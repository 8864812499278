import React from 'react';
import { useTranslation } from '@/src/i18n';

const CovidBanner = () => {
  const { t } = useTranslation();

  return (
    <div className="bandeau-alert-covid">
      <figure>
        <img
          src="/static/images/logo-rf-big.png"
          height="28"
          width="auto"
          alt="République Française"
        />
      </figure>
      <p>{t('discover-government-infos-about-covid')}</p>
    </div>
  );
};

export default CovidBanner;
