import * as React from 'react';
import { css, styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  NoSsr,
  Theme as MUITheme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IconChevronDown, List } from '@maiia/design-system';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@/src/i18n';
import { APP_ROUTES, DEFAULT_ADDRESSES } from '@/src/constants';
import { getArticleBySlug } from '@/src/hooks/articles/fetchers';
import ArticlesColumn from './ArticlesColumn';

/* JS styling */

const ListBold = styled(List)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(3)};

    li {
      font-weight: 700;

      &.with-disc {
        font-weight: 400;
        list-style-type: disc;

        margin-left: ${theme.spacing(3)};
      }
    }
  `,
);

const FooterAccordion = styled(Accordion)(
  ({ theme }) => css`
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid ${theme.palette.common.white};
    color: ${theme.palette.common.white};

    &:last-of-type {
      border-radius: 0;
    }

    .MuiButtonBase-root {
      padding-left: 0;
      padding-right: 0;

      .MuiAccordionSummary-content {
        margin-top: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(2)};

        h2 {
          margin-bottom: 0;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          width: auto;
          height: ${theme.spacing(3)};
          fill: ${theme.palette.common.white};
        }
      }
    }

    .MuiAccordionDetails-root {
      padding: 0 0 ${theme.spacing(3)} 0;

      .MuiGrid-item + .MuiGrid-item {
        padding-top: ${theme.spacing(1)};
      }

      ul {
        li {
          color: ${theme.palette.common.white};
        }
      }
    }
  `,
);

/* Component */

const DynamicFooterSpeciality = ({ speciality }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery<MUITheme>(theme =>
    theme.breakpoints.down('sm'),
  );

  const { data: indexArticle } = useQuery(
    [`${speciality.shortName}-articles`],
    () =>
      getArticleBySlug(speciality.shortName, {
        withFullHierarchy: true,
        depth: 3,
      }),
  );

  const popularSearchList = DEFAULT_ADDRESSES.filter(
    city => !['MONTPELLIER', 'LE HAVRE'].includes(city.shortName),
  ).map(city => ({
    title: `${speciality.label} ${city.label}`,
    url: `/${speciality.shortName}/${city.zipCode}-${city.shortName}`,
    id: city.id,
  }));

  const accordionTitle = (
    <Typography variant="h3" component="h2">
      {t('find-a-speciality', { speciality: speciality.label?.toLowerCase() })}
    </Typography>
  );

  const accordionContent = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Typography fontWeight={700} mb={2} mt={3}>
          {t('popular-searches')}
        </Typography>
        <Box pl={1}>
          <List listItems={popularSearchList} className="with-disc" />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ListBold
          listItems={[
            {
              title: t('all-our-speciality-practitioners', {
                speciality: speciality.label?.toLowerCase(),
              }),
              url: {
                pathname: APP_ROUTES.SPECIALITY.PRACTITIONERS_INDEX,
                query: {
                  speciality: speciality.shortName,
                },
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );

  return (
    <NoSsr>
      {isMobile ? (
        <>
          <FooterAccordion disableGutters>
            <AccordionSummary expandIcon={<IconChevronDown />}>
              {accordionTitle}
            </AccordionSummary>
            <AccordionDetails>{accordionContent}</AccordionDetails>
          </FooterAccordion>

          {indexArticle && (
            <ArticlesColumn
              indexArticle={indexArticle}
              speciality={speciality}
              isMobile={isMobile}
            />
          )}
        </>
      ) : (
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            {accordionTitle}
            {accordionContent}
          </Grid>
          {indexArticle && (
            <ArticlesColumn
              indexArticle={indexArticle}
              speciality={speciality}
              isMobile={isMobile}
            />
          )}
        </Grid>
      )}
    </NoSsr>
  );
};

export default DynamicFooterSpeciality;
