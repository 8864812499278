/* eslint-disable no-console */
import { fetchClientWithTimeout } from '@/src/utils';

/**
 * DO NOT use this in getStaticProps
 * The build would fail because of process.env.SERVER_ARTICLES_ENDPOINT
 * The build is done on Jenkins which is "env independant" and the image is used on other envs
 * TODO: define if we can set a fixed endpoint (production)
 *  */
// eslint-disable-next-line import/prefer-default-export
export const getArticleBySlug = (slug: string, query = {}, timeout = 5000) =>
  fetchClientWithTimeout(
    `${
      typeof window === 'undefined' ? process.env.SERVER_ARTICLES_ENDPOINT : ''
    }/articles/${slug}`,
    { query },
    timeout,
  );
