import { reducersGenerator, GET_LIST_SUCCESS } from '@docavenue/core';
import { UPDATE_DOCUMENT_SIZE_STORAGE } from '../actions/documentStorage';

const name = 'documents';
const surchargeReducer = reducerConfig => {
  const overridenConfig = { ...reducerConfig };
  const initialGetListSuccess = overridenConfig[GET_LIST_SUCCESS];

  overridenConfig[UPDATE_DOCUMENT_SIZE_STORAGE] = (state, action) => {
    const { totalDocumentSize } = action.payload;
    return { ...state, totalDocumentSize };
  };

  [GET_LIST_SUCCESS, `${GET_LIST_SUCCESS}/${name}`].forEach(actionType => {
    overridenConfig[actionType] = (state, action) => {
      const {
        payload: { totalDocumentSize, totalSizeAllowed },
      } = action;
      return initialGetListSuccess(
        { ...state, totalDocumentSize, totalSizeAllowed },
        action,
      );
    };
  });
  return overridenConfig;
};
const documents = reducersGenerator({
  name,
  surchargeReducer,
});

export default documents;
