import { SESSIONSTORAGE_KEYS } from '@/src/constants';
import { SSOUserDataType } from '../types/SSOUserData';

const clean = () => {
  window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.SSO_USER_DATA);
};

const setRedirectTarget = (redirectTarget: string) => {
  const storedData = {
    redirectTarget,
  };
  window.sessionStorage.setItem(
    SESSIONSTORAGE_KEYS.SSO_USER_DATA,
    JSON.stringify(storedData),
  );
};

const setSSOUserData = (token: string, data: SSOUserDataType['ssoProfile']) => {
  if (typeof window === 'undefined') {
    throw Error('Dont call setSSOUserData in server side');
  }
  const currentInfo = JSON.parse(
    window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.SSO_USER_DATA) ?? '{}',
  );
  const storedData: SSOUserDataType = {
    ...currentInfo,
    ssoProfile: data,
    token,
  };
  sessionStorage.setItem(
    SESSIONSTORAGE_KEYS.SSO_USER_DATA,
    JSON.stringify(storedData),
  );
};

const getSSOUserData = () => {
  if (typeof window === 'undefined') return;
  const item = window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.SSO_USER_DATA);
  if (!item) return;
  try {
    const data = JSON.parse(item) as SSOUserDataType;
    return data;
  } catch {}
};

export default function useSSOUserData() {
  const data = getSSOUserData();
  return {
    data,
    clean,
    setData: setSSOUserData,
    setRedirectTarget,
    getData: getSSOUserData,
  };
}
