import { createSelector } from 'reselect';
import { Locality } from '@maiia/model/generated/model/api-patient/api-patient';
import { RootStateOrAny } from 'react-redux';

export const noNeedPaymentSelector = createSelector(
  (state: any) => state?.authentication?.item?.userPatientInformation?.isDemo,
  (state: any) =>
    state?.practitioners?.item?.settings?.maxTeleconsultationPrice,
  (isDemoAccount, maxTeleconsultationPrice) =>
    isDemoAccount || maxTeleconsultationPrice === 0,
);

export const noNeedPaymentCardPractitionerSelector = createSelector(
  (state: any) => state?.authentication?.item?.userPatientInformation?.isDemo,
  (state: any) =>
    Object.keys(state?.temporaryAppointments?.item).length
      ? state?.temporaryAppointments?.item?.cardHcd?.settings
          ?.maxTeleconsultationPrice
      : state?.cards?.item?.settings?.maxTeleconsultationPrice,
  (isDemoAccount, maxTeleconsultationPrice) =>
    isDemoAccount || maxTeleconsultationPrice === 0,
);

export const noNeedPaymentRDVSelector = createSelector(
  (state: any) => state?.authentication?.item?.userPatientInformation?.isDemo,
  (state: any) =>
    state?.temporaryAppointments?.item?.cardHcd?.settings
      ?.maxTeleconsultationPrice,
  (isDemoAccount, maxTeleconsultationPrice) =>
    isDemoAccount || maxTeleconsultationPrice === 0,
);

export const isDemoAccountSelector = createSelector(
  (state: any) => state?.authentication?.item?.userPatientInformation?.isDemo,
  isDemoAccount => isDemoAccount,
);

export const temporaryAppointmentSelector = createSelector(
  (state: any) => state?.temporaryAppointments?.item,
  item => item,
);

export const relatedCitiesItemsSelector = state => state.relatedCities.items;

const defaultSuggestionItem = {};
export const suggestionItemSelector = state =>
  state.suggestions.item || defaultSuggestionItem;

export const localityItemSelector = state =>
  state.localities.item as Locality | null;

export const addressItemSelector = state => state.addresses.item;

export const isEncryptionServiceStatusFetchedSelector = (
  state: RootStateOrAny,
) => state.announcements.status.encryption === 'fetched';

export const mapServerUrlSelector = (state: any) =>
  state?.announcements?.mapServerUrl;
