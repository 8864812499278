import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { APP_ROUTES } from '../constants';

export const exceptionPagePartners: string[] = [
  APP_ROUTES.SPECIALITY.LOCALITY_SLUG_RDV,
  APP_ROUTES.APPOINTMENT_CONFIRMATION,
  APP_ROUTES.LOGIN,
  APP_ROUTES.REGISTER,
  APP_ROUTES.APPOINTMENT.ID,
  APP_ROUTES.SMS_CONFIRMATION,
  APP_ROUTES.PROFILE_CREATION,
  APP_ROUTES.FORGOT_PASSWORD,
];

const domainWhiteLabel = ['partners'];

export const useIsPartners = () => {
  const [isPartners, setIsPartners] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    if (
      domainWhiteLabel.includes(
        (window as any)?.location?.hostname.split('.')?.[0],
      ) &&
      exceptionPagePartners.includes(router.pathname)
    ) {
      setIsPartners(true);
    } else {
      setIsPartners(false);
    }
  }, [router.pathname]);

  // TODO: keep in mind that we may encounter cases where it's necessary to calculate regularily the status of isPartners
  return isPartners;
};
