import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import config from '@/src/config';
import { serverConfigurationActions } from '@/src/actions';

const serverTimeRegex = /status OK - (.*)/;

/**
 * Get the time offset between the server time and the client time.
 * Used to show the correct countdown in the waiting room before a TLC.
 * @returns null
 */
const TimeOffsetDetector = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const run = async () => {
      try {
        // TODO: this is a bug since CORS is not activated
        const healthcheck = await fetch(
          `${config.get('API_ENDPOINT')}/healthcheck`,
        ).then(res => {
          if (!res.ok) {
            throw res;
          }

          return res.text();
        });
        const serverTimeMatch = healthcheck.match(serverTimeRegex);
        if (serverTimeMatch) {
          const [, serverTime] = serverTimeMatch;
          dispatch(serverConfigurationActions.setTimeOffset(serverTime));
        }
      } catch (e) {
        console.error(`Could not communicate with backend to get server time.`);
      }
    };
    run();
  }, []);

  return null;
};
export default TimeOffsetDetector;
