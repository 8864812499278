import config from '@/src/config';

const buildArticleUrl = (link: string) => {
  const [speciality, ...slugs] = link
    ?.replace(`${config.get('NEXT_PUBLIC_PAT_MAIIA_NEWS_BASEURL')}/`, '')
    .split('/');
  return `/${speciality}/article/${slugs.join('/')}`;
};

export default buildArticleUrl;
