import { Rest } from '@docavenue/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import config from './config';

const httpOptions: { [k: string]: any } = {};
if (typeof window === 'undefined' && process.env.HTTPS_PROXY) {
  // eslint-disable-next-line no-eval
  const HttpsProxyAgent = eval('require("https-proxy-agent")');
  httpOptions.agent = new HttpsProxyAgent(process.env.HTTPS_PROXY);
}
if (typeof window === 'undefined' && process.env.SERVER_API_KEY) {
  // eslint-disable-next-line no-buffer-constructor
  const buff = new Buffer(process.env.SERVER_API_KEY);
  const base64data = buff.toString('base64');
  httpOptions.headers = {
    'X-Authorization': `Basic ${base64data}`,
    'Application-Type': 'WEB_PUBLIC',
  };
}
export default new Rest(
  {
    api: (process as any).browser
      ? config.get('API_ENDPOINT')
      : config.get('SERVER_API_ENDPOINT'),
    auth: config.get('AUTH_ENDPOINT'),
    address: config.get('ADDRESS_ENDPOINT'),
    algolia: config.get('ALGOLIA_ENDPOINT'),
    defaultApiScope: 'pat',
  },
  httpOptions,
  typeof window === 'undefined' ? null : localStorage,
);
