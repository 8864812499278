import * as React from 'react';
import clsx from 'clsx';
import { devices } from '@maiia/design-system';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useCurrentUser } from '@docavenue/components';
import { cn, Separator } from '@kiiwi/ui';
import { useTranslation } from '@/src/i18n';
import config from '../../../src/config';
import MenuAboutMaiia from '@/components/organisms/Footer/MenuAboutMaiia';
import MenuApplications from './MenuApplications';
import MenuSocial from './MenuSocial';
import { APP_ROUTES } from '@/src/constants';
import useResponsive from '@/src/hooks/useResponsive';
import SpecialitiesAndMenuInformations from './SpecialitiesAndMenuInformations';
import Support from '@/components/organisms/Footer/Support';
import DynamicFooter from '@/components/organisms/Footer/DynamicFooter/DynamicFooter';

/* JS styling */

const FooterWrapper = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    padding: ${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(3)};
    @media ${devices.desktop} {
      padding-top: ${theme.spacing(3)};
      padding-left: ${theme.spacing(8)};
      padding-right: ${theme.spacing(8)};
    }

    h4,
    h2,
    h3 {
      color: ${theme.palette.common.white};
      font-weight: 600;
    }

    .with-disc {
      li {
        list-style-type: disc;
        margin-left: ${theme.spacing(1.5)};
      }
    }

    &.dynamic {
      padding-bottom: 0;

      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(3, 2)};
      }

      ${theme.breakpoints.up('md')} {
        padding: ${theme.spacing(3, 8)};
      }
    }
  `,
);

const SectionsDivider = styled(Divider, {
  shouldForwardProp: () => true,
})(
  ({ theme }) => css`
    border-color: ${theme.palette.common.white};
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
  `,
);

/* Component */

type Props = {
  className?: string;
  pageProps?: React.ComponentProps<'div'>;
};

const Footer = ({ className, pageProps }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const isHomepage = router.pathname === APP_ROUTES.HOME;
  const isBcb = router.pathname.startsWith(APP_ROUTES.MEDICAMENTS.BASE_URL);

  return (
    <>
      <Support />
      <div className={cn('pt-6', isBcb ? 'bg-[#4eb3c9]' : 'bg-primary-enable')}>
        {!isHomepage && !currentUser && (
          <FooterWrapper
            data-testid="dynamic-footer"
            className={clsx(
              'footer dynamic',
              className && `footer--${className}`,
            )}
          >
            <DynamicFooter pageProps={pageProps} />
            {!isMobile && <Separator className="mt-6 bg-surface-white" />}
          </FooterWrapper>
        )}

        <FooterWrapper
          data-testid="main-footer"
          className={clsx(
            'pt-0 text-white',
            className,
            isBcb ? 'bg-[#4eb3c9]' : 'bg-primary-enable',
          )}
        >
          {isHomepage && !currentUser && (
            <>
              <SpecialitiesAndMenuInformations />

              {!isMobile && <SectionsDivider />}
            </>
          )}

          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
          >
            <MenuAboutMaiia />

            <MenuApplications />
          </Stack>

          <SectionsDivider />

          <Stack direction="row" justifyContent="space-between">
            <Box
              textAlign={isMobile ? 'center' : 'left'}
              width={isMobile ? '100%' : '40%'}
            >
              <Typography sx={{ fontWeight: '600' }}>
                {t('copyright_for_footer', {
                  year: new Date().getFullYear(),
                })}
              </Typography>

              {config.get('RELEASE_VERSION') && (
                <p className="percy-hidden">
                  {t(`footer_version_number`, {
                    version: config.get('RELEASE_VERSION'),
                    buildNumber: config.get('BUILD_NUMBER', 'dev'),
                  })}
                </p>
              )}

              <Typography pt={1.5} variant="body2">
                {t('footer__priorities_practitioners')}
              </Typography>
            </Box>

            {!isMobile && <MenuSocial />}
          </Stack>
        </FooterWrapper>
      </div>
    </>
  );
};

export default Footer;
