import { createTheme } from '@mui/material/styles';
import '@mui/lab/themeAugmentation';
import {
  brown,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  orange,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';

const baseThemeParams = {
  breakpoints: {
    values: {
      // TODO : Huge refacto ? xs must start at 0
      xs: 320,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      // turquoise-green
      light: '#86e1db',
      main: '#64c9c3',
      dark: '#3fb0aa',
      contrastText: '#ffffff',
    },
    // secondary pink (accent)
    secondary: {
      // pink
      light: '#fcd8dd',
      main: '#f68092',
      dark: '#e35d78',
      contrastText: '#ffffff',
    },
    info: {
      light: '#a8a8a8',
      main: '#888888',
      dark: '#606060',
    },
    infoBlue: {
      light: '#4eb3c9',
      main: '#3e8fa1',
      dark: '#1a606f',
      contrastText: '#fff',
    },
    warning: {
      // MUI default warning colors
      light: '#ed6c02',
      main: '#ff9800',
      dark: '#e65100',
      contrastText: '#fff',
    },
    success: {
      // green
      light: '#82cca7',
      main: '#6dd4b4',
      dark: '#13a55c',
    },
    text: {
      primary: '#606060',
      secondary: 'rgba(0,0,0,0.6)',
      medium: '#a1a1a1',
      link: '#4eb3c9',
    },
    background: {
      primary: '#ffffff',
      primaryTransparent: 'rgba(255, 255, 255, 0.3)',
      primaryVeryTransparent: 'rgba(255, 255, 255, 0.15)',
      secondary: '#f1f4f5',
      secondaryLight: '#fde6e9',
      secondaryTooltip: '#fcf2f3',
      gradientPrimary: 'linear-gradient(90deg, #64c9c3, #4eb3c9)',
      welcomeTerminal: 'linear-gradient(90deg, #64c9c3, #4eb3c9)',
      lightGreen: '#e6eeed',
      green: '#68d391',
      greenAlt: '#28C38F',
      darkTurquoiseGreen: '#50A19C',
      blueHighlight: '#ccebe9',
      secondaryBlue: '#4eb3c9',
      borderLight: '#d2d2d2',
      successLight: '#f0faf9',
      errorLight: '#fff5f5',
      monochromeLight: '#f2f5f7',
      xLightBlue: '#eff9f9',
      xLightPink: '#fef2f4',
      infoLightBlue: '#edf7fa',
      lightGrey: '#e0e3e4',
      darkGreyishBlue: '#596068',
      greyishBlue: '#788088',
      darkGrey: '#444d56',
    },
    status: {
      online: '#86f1b0',
    },
    patients: {
      0: red[300],
      1: purple[300],
      2: indigo[300],
      3: lightBlue[300],
      4: teal[300],
      5: lightGreen[300],
      6: yellow[300],
      7: orange[300],
      8: brown[300],
    },
  },
  typography: {
    fontFamily: "'Source Sans 3', sans-serif",
    h1: {
      fontSize: '46px',
      fontWeight: 700,
      marginBottom: '24px',
      fontFamily: "'Outfit', sans-serif",
    },
    h2: {
      fontSize: '36px',
      fontWeight: 700,
      marginBottom: '20px',
      fontFamily: "'Outfit', sans-serif",
    },
    h3: {
      fontSize: '28px',
      fontWeight: 700,
      marginBottom: '16px',
      fontFamily: "'Outfit', sans-serif",
    },
    h4: {
      fontSize: '24px',
      fontWeight: 700,
      marginBottom: '12px',
      fontFamily: "'Outfit', sans-serif",
    },
    h5: {
      fontSize: '18px',
      fontWeight: 700,
      marginBottom: '8px',
      fontFamily: "'Outfit', sans-serif",
    },
    h6: {
      fontSize: '16px',
      fontWeight: 700,
      marginBottom: '4px',
      fontFamily: "'Outfit', sans-serif",
    },
    body1: {
      fontSize: '16px',
      strong: {
        fontWeight: 600,
      },
    },
    body2: {
      fontSize: '14px',
    },
    subtitle1: {
      fontSize: '12px',
    },
    subtitle2: {
      fontSize: '10px',
    },
    button: {
      textTransform: 'none' as const,
      fontSize: '16px',
      // TODO: set 14px for medium button, 10px for small and 18px for large
      lineHeight: '1.35',
      fontWeight: 500,
    },
  },
  headerHeight: 56,
};

// Create a theme instance.
const baseTheme = createTheme(baseThemeParams);

export const defaultTheme = {
  ...baseThemeParams,
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    // Shared across all button types (Button, IconButton, ...)
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'initial',
          },
        },
      },
    },
    // Used only on Button
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple!
        disableElevation: true, // No more shadow!
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          minWidth: 150,
          borderWidth: '2px',
          borderStyle: 'solid',
          borderRadius: 100,
          boxShadow: 'none',

          '&:hover': {
            borderWidth: '2px',
            boxShadow: 'none',
          },

          '&.Mui-disabled': {
            borderWidth: '2px',
          },
        },
        sizeMedium: {
          padding: '4px 22px',
        },
        sizeLarge: {
          fontSize: 18,
          padding: '10px 32px',
        },
        containedPrimary: {
          '&.Mui-disabled': {
            borderColor: 'transparent',
          },
          '&:not(.Mui-disabled)': {
            borderColor: baseTheme.palette.primary.main,

            '&:hover': {
              borderColor: baseTheme.palette.primary.dark,
            },
          },
        },
        containedSecondary: {
          '&.Mui-disabled': {
            borderColor: 'transparent',
          },
          '&:not(.Mui-disabled)': {
            borderColor: baseTheme.palette.secondary.main,

            '&:hover': {
              borderColor: baseTheme.palette.secondary.dark,
            },
          },
        },
        outlinedPrimary: {
          '&:not(.Mui-disabled)': {
            // The default border color for outlined button is transparent by 50%.
            borderColor: baseTheme.palette.primary.main,

            '&:hover': {
              color: baseTheme.palette.primary.contrastText,
              backgroundColor: baseTheme.palette.primary.main,
            },
          },
        },
        outlinedSecondary: {
          '&.Mui-disabled': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          '&:not(.Mui-disabled)': {
            // The default border color for outlined button is transparent by 50%.
            borderColor: baseTheme.palette.secondary.main,

            '&:hover': {
              color: baseTheme.palette.secondary.contrastText,
              backgroundColor: baseTheme.palette.secondary.main,
            },
          },
        },
        text: {
          borderColor: 'transparent',

          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        textInfo: {
          '&:hover': {
            color: '#64c9c3',
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          fontSize: 24,

          '&:disabled': {
            backgroundColor: grey[400],
            color: grey[700],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          // ':before, &:hover:not(.Mui-disabled):before': {
          '&:before': {
            // remove the bottom border that is black by default
            borderBottom: '1px solid transparent',
          },
          '&:hover:not(.Mui-disabled)': {
            backgroundColor: '#f1f4f5',
            '&:before': {
              borderBottom: '1px solid transparent',
            },
            '&:after': {
              left: 0,
              right: 0,
              bottom: 0,
              content: "''",
              position: 'absolute',
              transform: 'scaleX(1)',
              transition: 'transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
              borderBottom: '2px solid #64c9c3',
              pointerEvents: 'none',
            },
          },
          backgroundColor: '#f1f4f5',
        },
        input: {
          padding: '8px',
          height: 44,
          boxSizing: 'border-box' as const,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    // Overrides
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: baseTheme.typography.body1.fontSize,
          fontWeight: baseTheme.typography.body1.fontWeight,
          '&.MuiAlert-standardInfoBlue': {
            '& a': {
              color: baseTheme.palette.infoBlue.light,
            },
          },
        },
      },
    },
    // MuiTreeItem: {
    //   styleOverrides: {
    //     root: {
    // // https://mui.com/customization/how-to-customize/#state-classes
    //       '& .Mui-selected > .MuiTreeItem-label > .MuiTypography-root': {
    //         color: '#3fb0aa',
    //       },
    //     },
    //   },
    // },
    MuiDialog: {
      styleOverrides: {
        container: {
          minWidth: '100%',
          [baseTheme.breakpoints.down('sm')]: {
            position: 'relative',
          },
        },
        paper: {
          minWidth: '40%',
          [baseTheme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 0,
            margin: 0,
            width: '100vw',
            maxWidth: '100vw',
            // Dialogs with small content will have a weird design on mobile view.
            // To counter that, we set a minimum height
            minHeight: '30%',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          display: 'flex',
          alignItems: 'center',
          minHeight: baseTheme.spacing(6),
          background: baseTheme.palette.background.gradientPrimary,
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          padding: baseTheme.spacing(2, 3, 1.5, 3),
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          // TODO: After some recent updates, it seems that we can remove the styles below ?
          '.MuiSwitch-thumb': {
            boxShadow: '0px 3px 4px -1px rgba(0, 0, 0, 0.25)',
          },

          '.Mui-checked.MuiSwitch-colorPrimary': {
            '.MuiSwitch-thumb': {
              color: baseTheme.palette.background.secondary,
            },
            '&.MuiSwitch-switchBase + .MuiSwitch-track': {
              backgroundColor: baseTheme.palette.success.main,
              opacity: 1,
            },

            '&.MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track ': {
              opacity: 0.5,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'large' && {
            paddingBlock: '12px',
          }),
          ...(ownerState.size === 'medium' && {
            paddingBlock: '8px',
          }),
          ...(ownerState.size === 'small' && {
            paddingBlock: '4px',
          }),
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          borderRadius: baseTheme.spacing(2.5, 2.5, 0, 0),
          overflow: 'hidden',
          padding: baseTheme.spacing(4, 2),

          '&:before': {
            content: "''",
            width: 30,
            height: 6,
            backgroundColor: baseTheme.palette.grey[300],
            borderRadius: 3,
            position: 'absolute',
            top: 8,
            left: 'calc(50% - 15px)',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
};

export default createTheme(defaultTheme);
