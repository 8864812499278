import { isEmpty } from 'lodash';
import {
  Appointment,
  CardHcd,
  CareGiverDTO,
} from '@maiia/model/generated/model/api-patient/api-patient';
import { getPictureSource } from './card';

type CareTeamCardHcdWithCard = CareGiverDTO &
  Required<Pick<CareGiverDTO, 'card'>>;

const ALLOW_PHONE_COUNTRY_CODES = [
  'fr',
  'lu',
  'ch',
  'be',
  'gf',
  'gp',
  'mq',
  'yt',
  'nc',
  'pf',
  're',
  'pm',
  'wf',
  'eg',
  'es',
  'de',
  'gb',
  'it',
  'ie',
  'nl',
  'ma',
  'dz',
  'tn',
];

// Update by hand when change list allow country
// https://github.com/jackocnr/intl-tel-input/blob/master/src/js/data.js
// ALLOW_PHONE_COUNTRY_CODES.map(x=>{c = allCountries.find(c=>c.iso2===x); return '+'+c.dialCode})
const ALLOW_DIAL_CODES = [
  '+33',
  '+352',
  '+41',
  '+32',
  '+594',
  '+590',
  '+596',
  '+262',
  '+687',
  '+689',
  '+262',
  '+508',
  '+681',
  '+20',
  '+34',
  '+49',
  '+44',
  '+39',
  '+353',
  '+31',
  '+212',
  '+213',
  '+216',
];

const isAllowPhoneDialCode = (value: string) =>
  ALLOW_DIAL_CODES.some(code => value.startsWith(code));

export const isValidPhoneNumber = (value: any) => {
  if (!value) return false;
  if (typeof window === 'undefined') {
    console.error('Not supported SSR yet!');
    return false;
  }
  const { intlTelInputUtils } = window as any;
  if (!intlTelInputUtils) {
    console.error('intlTelInputUtils not ready yet!');
    return false;
  }
  return (
    intlTelInputUtils.isValidNumber(value) &&
    intlTelInputUtils.getNumberType(value) ===
      intlTelInputUtils.numberType.MOBILE &&
    isAllowPhoneDialCode(value)
  );
};

// TODO : refactor this
export const isValidFixedPhoneNumber = (value: string) => {
  if (!value) return false;
  if (typeof window === 'undefined') {
    console.error('Not supported SSR yet!');
    return false;
  }
  const { intlTelInputUtils } = window as any;
  if (!intlTelInputUtils) {
    console.error('intlTelInputUtils not ready yet!');
    return false;
  }
  return (
    intlTelInputUtils.isValidNumber(value) &&
    intlTelInputUtils.getNumberType(value) ===
      intlTelInputUtils.numberType.FIXED_LINE &&
    isAllowPhoneDialCode(value)
  );
};

export const isAllowPhoneCountryCode = (value: string) =>
  ALLOW_PHONE_COUNTRY_CODES.includes(value.toLowerCase());

export const getSubstitute = (item?: Appointment | CardHcd) => {
  const isSubstitute =
    !isEmpty(item?.substituteData) ||
    !isEmpty((item as any)?.availability?.substitute);

  const substitute = ((item as any)?.availability?.substitute ||
    item?.substituteData) as CardHcd['substituteData'];
  return {
    isSubstitute,
    substitute: {
      ...substitute,
      ...(isSubstitute
        ? {
            avatar: '/static/images/avatar-substitute.svg',
            fullName: `${substitute?.firstName} ${substitute?.lastName}`,
          }
        : {}),
    },
  };
};

export const getAvatar = item => {
  const { isSubstitute } = getSubstitute(item);
  const isHiddenOnline = item?.cardHcd?.settings?.isVisibleOnline === false;
  const avatar = {
    substitute: isSubstitute && '/static/images/avatar-substitute.svg',
    invisible:
      isHiddenOnline &&
      (getPictureSource(item?.cardHcd?.center) ||
        '/static/images/default_center.svg'),
    default: getPictureSource(item?.cardHcd),
  };
  return avatar.substitute || avatar.invisible || avatar.default;
};

export const getCardFullName = (t: Function, item?: CardHcd | null) => {
  if (!item) return '';
  let medicalEntity = '';
  const { professional = {}, center, type } = item;

  if (type !== 'PRACTITIONER') {
    return center?.name;
  }

  const { firstName = '', lastName = '', speciality } = professional;
  const isVisibleOnline = item?.settings?.isVisibleOnline === true;

  medicalEntity = `${firstName} ${lastName}`;

  if (!isVisibleOnline) {
    medicalEntity = center?.name ?? '';
  }

  const professionalTitle =
    speciality?.isParamedical === false && isVisibleOnline
      ? t('professional_title')
      : '';

  return `${professionalTitle}${medicalEntity}`;
};

export const getAppointmentCardUrl = (
  item: Appointment & { cardHcd?: CardHcd },
) =>
  (item?.cardHcd?.settings?.isVisibleOnline === false
    ? item?.cardHcd?.center?.url
    : item?.cardHcd?.url) ?? '/';

export const getPhoneNumber = (card: CardHcd) =>
  card?.publicInformation?.officeInformation?.phoneNumber ||
  card?.center?.publicInformation?.officeInformation?.phoneNumber;

const isPharmacy = (cardHcd?: CardHcd) =>
  cardHcd?.type === 'CENTER' &&
  cardHcd?.center?.speciality?.shortName === 'pharmacie';

const getCardNameToCompare = (card: CardHcd) =>
  card.type === 'CENTER'
    ? card.center?.name || ''
    : card.professional?.lastName || '';
const compareCareTeamCardByName = (
  a: CareTeamCardHcdWithCard,
  b: CareTeamCardHcdWithCard,
) => getCardNameToCompare(a.card).localeCompare(getCardNameToCompare(b.card));

const getCardValueByType = (card: CardHcd) => (card.type === 'CENTER' ? 1 : 0); // small value go first, so this will lets center goes last
const compareCareTeamCardByType = (
  a: CareTeamCardHcdWithCard,
  b: CareTeamCardHcdWithCard,
) => {
  return getCardValueByType(a.card) - getCardValueByType(b.card);
};

const entityHasCardInfo = (
  entity: CareGiverDTO,
): entity is CareTeamCardHcdWithCard => Boolean(entity.card);

export const sortCareTeam = <T extends CareTeamCardHcdWithCard>(data: T[]) => {
  return data
    .filter(entityHasCardInfo)
    .sort(
      (a, b) =>
        compareCareTeamCardByType(a, b) || compareCareTeamCardByName(a, b),
    );
};

export const splitCareTeam = (data: CareGiverDTO[]) => {
  const dataHasCardInfo = data.filter(entityHasCardInfo);

  const entities = dataHasCardInfo
    .filter(
      entity =>
        entity.card?.professional?.practitionerId || !isPharmacy(entity.card),
    )
    .sort(
      (a, b) =>
        compareCareTeamCardByType(a, b) || compareCareTeamCardByName(a, b),
    );

  const pharmacies = dataHasCardInfo
    .filter(pharmacy => isPharmacy(pharmacy.card))
    .sort(compareCareTeamCardByName);

  return {
    hasEntity: entities.length > 0,
    hasPharmacies: pharmacies.length > 0,
    entities,
    pharmacies,
  };
};

/**
 * Transforms a relative url into a SEO url. Before, we would use req.hostname but it didn't make sense because the only URL that we actually index are production index. We want all the following urls :
 * - https://partners.maiia.com/pharmacie/75000-PARIS
 * - https://a-pharmacy.maiia.com/pharmacie/75000-PARIS
 * - https://pat.maiia.com/pharmacie/75000-PARIS
 * - https://*.maiia.com/pharmacie/75000-PARIS
 *
 * to have a canonical to :
 * https://www.maiia.com/pharmacie/75000-PARIS
 *
 * Note : staging/test/etc environmment doesn't count because they have a global noindex.
 * @param url a relative url to a page
 * @returns an url starting with www.maiia.com
 */
export const urlToCanonical = (url?: string) =>
  url?.startsWith('/')
    ? process.env.NEXT_PUBLIC_MAIIA_CANONICAL_BASEURL + url
    : `${process.env.NEXT_PUBLIC_MAIIA_CANONICAL_BASEURL}/${url}`;
