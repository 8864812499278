import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Box, Stack } from '@mui/material';
import { socialFooter } from './footerMenus';
import { useTranslation } from '@/src/i18n';

/* Component */

const MenuSocial = () => {
  const { t } = useTranslation();
  const footerMenusTranslated = socialFooter(t);

  return (
    <Stack direction="row" justifyContent="center" mt={2} spacing={3}>
      {footerMenusTranslated.map(item => (
        <Box
          key={item.id}
          sx={theme => ({
            svg: { width: 'auto', height: theme.spacing(4.5) },
          })}
        >
          <a
            href={item.url}
            data-cy={item?.id}
            target="_blank"
            rel="noreferrer"
            title={item.title}
            aria-label={item.title}
          >
            {item.icon}
          </a>
        </Box>
      ))}
    </Stack>
  );
};

export default MenuSocial;
