import { useSelector } from 'react-redux';
import { useCurrentUser } from '@docavenue/components';

export const prescriberSelector = (state: any) =>
  Boolean(state?.authentication?.item?.userPatientInformation?.isPrescriber);

const useIsPrescriber = () => {
  const isPrescriber = useSelector(prescriberSelector);
  const currentUser = useCurrentUser();
  return isPrescriber || currentUser?.role?.name === 'ASSISTED_PATIENT';
};

export default useIsPrescriber;
