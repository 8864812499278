import { SET_LOCATION } from '../actions/geoip';

const initialState = {
  location: {},
};

const geoip = (state = initialState, action: any = {}) => {
  const { type } = action;

  if (type === SET_LOCATION) {
    return { ...state, ...action.payload };
  }

  return state;
};

export default geoip;
