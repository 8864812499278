import {
  actionsGenerationHelper,
  actionsGenerationHelperV2,
  actionsGeneratorV2,
  hcdActions,
  tlcCardsActions,
} from '@docavenue/core';
import temporaryAppointmentsActions from './temporaryAppointments';
import geoipActions from './geoip';
import serverConfigurationActions from './serverConfiguration';
import closestAvailabilitiesActions from './closestAvailabilitiesActions';

const [
  authActions,
  logoutActions,
  videoSessionsActions,
  appointmentsActions,
  appointmentsTemplateActions,
  localitiesActions,
  cardsActions,
  appointmentSpecialitiesActions,
  consultationReasonsByNameActions,
  practitionersActions,
  instructionsActions,
  registrationsActions,
  confirmationsActions,
  profilesActions,
  paymentMethodsActions,
  setupIntentsActions,
  careTeamActions,
  usersActions,
  passwordResetsActions,
  documentsActions,
  paymentsIntents,
  nexmoCallsActions,
  shareDocumentsActions,
  linkAccountsActions,
  patPublicPharmacyActions,
  pharmacyUsersActions,
  centersActions,
  patientTokensActions,
  anonymousSignaturesActions,
  anonymousAppointmentsActions,
  relatedCitiesActions,
  waitingAttemptsActions,
] = actionsGenerationHelper([
  {
    resource: 'authentication',
    chunkUrlResource: 'login',
    scope: 'auth',
  },
  {
    resource: 'logout',
    scope: 'auth',
  },
  {
    resource: 'videoSessions',
    chunkUrlResource: 'video-sessions',
  },
  {
    resource: 'appointments',
  },
  {
    resource: 'appointmentsTemplate',
    chunkUrlResource: 'appointments/template',
  },
  {
    resource: 'localities',
    scope: 'pat-public',
  },
  {
    resource: 'cards',
    scope: 'pat-public',
    chunkUrlResource: 'hcd/cards',
  },
  {
    resource: 'appointmentSpecialities',
    scope: 'pat-public',
    chunkUrlResource: 'specialities',
  },
  {
    resource: 'consultationReasonsByName',
    chunkUrlResource: 'consultation-reason-hcd',
    scope: 'pat-public',
  },
  {
    resource: 'practitioners',
    chunkUrlResource: 'practitioner-hcd',
    scope: 'pat-public',
  },
  {
    resource: 'instructions',
    chunkUrlResource: 'instruction-hcd',
    scope: 'pat-public',
  },
  {
    resource: 'registrations',
    scope: 'pat-public',
  },
  {
    resource: 'confirmations',
  },
  {
    resource: 'profiles',
  },
  {
    resource: 'paymentMethods',
    chunkUrlResource: 'payment-methods',
  },
  {
    resource: 'setupIntents',
    chunkUrlResource: 'setup-intents',
  },
  {
    resource: 'careTeam',
    chunkUrlResource: 'care-team',
  },
  {
    resource: 'users',
  },
  {
    resource: 'passwordResets',
    chunkUrlResource: 'password-resets',
    scope: 'auth',
  },
  {
    resource: 'documents',
  },
  {
    resource: 'paymentsIntents',
    chunkUrlResource: 'payment-intents',
  },
  {
    resource: 'nexmoCalls',
    chunkUrlResource: 'nexmo/calls',
  },
  {
    resource: 'shareDocuments',
    chunkUrlResource: 'share-documents',
  },
  {
    resource: 'linkAccounts',
    chunkUrlResource: 'link-accounts',
  },
  {
    resource: 'pharmacies',
    scope: 'pat-public',
  },
  {
    resource: 'pharmacyUsers',
    chunkUrlResource: 'pharmacy-users',
  },
  {
    resource: 'centers',
    chunkUrlResource: 'center-hcd',
    scope: 'pat-public',
  },
  {
    resource: 'patientTokens',
    chunkUrlResource: 'patients-tokens',
    scope: 'pat-public',
  },
  {
    resource: 'anonymousSignatures',
    chunkUrlResource: 'signatures',
    scope: 'pat-anon',
  },
  {
    resource: 'anonymousAppointments',
    chunkUrlResource: 'appointments',
    scope: 'pat-anon',
  },
  {
    resource: 'relatedCities',
    chunkUrlResource: 'cities',
    scope: 'pat-public',
  },
  {
    resource: 'waitingAttempts',
    chunkUrlResource: 'waiting-attempts',
    scope: 'pat',
  },
]);

const [
  centersTreeActions,
  favoritePractitionersActions,
  resendConfirmationsActions,
  phoneRequestsActions,
  specialitiesActions,
  emailConfirmationActions,
  availabilitiesActions,
  feedbackTlcActions,
  welcomeTerminalActions,
  centerHcdActions,
  terminalInstructionsActions,
  amcUserActions,
  brandingsActions,
  insuranceActions,
  amcActions,
  agendaSettingsActions,
] = actionsGenerationHelperV2([
  {
    resource: 'centersTree',
    chunkUrlResource: 'center-hcd',
    scope: 'pat-public',
  },
  {
    resource: 'favoritePractitioners',
    chunkUrlResource: 'favorite',
  },
  {
    resource: 'resendConfirmations',
    chunkUrlResource: 'resend-confirmations',
    version: '2.0.0',
  },
  {
    resource: 'phoneRequests',
    chunkUrlResource: 'phone-requests',
  },
  {
    resource: 'specialities',
    chunkUrlResource: 'specialities',
    scope: 'pat-public',
  },
  {
    resource: 'emailConfirmations',
    chunkUrlResource: 'email-confirmation',
    scope: 'pat-public',
  },
  {
    resource: 'availabilities',
    scope: 'pat-public',
    version: '2.0.0',
  },
  {
    resource: 'feedbackTlc',
    chunkUrlResource: 'feedback-tlc',
    version: '2.0.0',
  },
  {
    resource: 'welcomeTerminal',
    chunkUrlResource: 'welcome-terminal',
    scope: 'pat-public',
  },
  {
    resource: 'centerHcd',
    chunkUrlResource: 'center-hcd',
    scope: 'pat-public',
  },
  {
    resource: 'terminalInstructions',
    chunkUrlResource: 'terminal-instructions',
    scope: 'pat-public',
  },
  {
    resource: 'amcUserData',
    chunkUrlResource: 'amc-link',
    scope: 'pat',
  },
  {
    resource: 'brandings',
    scope: 'pat-public',
  },
  {
    resource: 'insurance',
    chunkUrlResource: 'amc',
    scope: 'pat',
  },
  {
    resource: 'amcs',
    scope: 'pat-public',
  },
  {
    resource: 'agendaSettings',
    chunkUrlResource: 'agendaSettings',
  },
]);

const suggestionsEmptyQuery = () => ({
  resource: 'suggestions',
  type: 'EMPTY_QUERY',
});

const checkBirthdateActions = actionsGeneratorV2({
  resource: 'check-birthdate',
  scope: 'pat-public',
});

const linkSSOAction = actionsGeneratorV2({
  resource: 'ssoLinkSSO',
  chunkUrlResource: 'sso/link-sso',
});

export {
  favoritePractitionersActions,
  authActions,
  logoutActions,
  videoSessionsActions,
  appointmentsActions,
  appointmentsTemplateActions,
  specialitiesActions,
  localitiesActions,
  hcdActions,
  availabilitiesActions,
  cardsActions,
  appointmentSpecialitiesActions,
  consultationReasonsByNameActions,
  practitionersActions,
  instructionsActions,
  temporaryAppointmentsActions,
  registrationsActions,
  confirmationsActions,
  profilesActions,
  tlcCardsActions,
  resendConfirmationsActions,
  phoneRequestsActions,
  paymentMethodsActions,
  setupIntentsActions,
  careTeamActions,
  usersActions,
  passwordResetsActions,
  documentsActions,
  paymentsIntents,
  nexmoCallsActions,
  shareDocumentsActions,
  linkAccountsActions,
  patPublicPharmacyActions,
  pharmacyUsersActions,
  centersActions,
  geoipActions,
  patientTokensActions,
  anonymousSignaturesActions,
  anonymousAppointmentsActions,
  centersTreeActions,
  serverConfigurationActions,
  relatedCitiesActions,
  waitingAttemptsActions,
  suggestionsEmptyQuery,
  emailConfirmationActions,
  closestAvailabilitiesActions,
  feedbackTlcActions,
  welcomeTerminalActions,
  centerHcdActions,
  terminalInstructionsActions,
  amcUserActions,
  brandingsActions,
  insuranceActions,
  amcActions,
  agendaSettingsActions,
  checkBirthdateActions,
  linkSSOAction,
};
