import * as React from 'react';
import { styled, css } from '@mui/material/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Stack, Box } from '@mui/material';
import { applicationFooter } from './footerMenus';
import { useTranslation } from '@/src/i18n';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import useResponsive from '@/src/hooks/useResponsive';

/* JS styling */

const Item = styled('li')(
  ({ theme }) => css`
    svg {
      width: auto;
      height: ${theme.spacing(4)};
    }
  `,
);

/* Component */

const MenuApplications = () => {
  const { t } = useTranslation();
  const footerMenusTranslated = applicationFooter(t);
  const { isMobile } = useResponsive();

  return (
    <Box
      sx={{
        ...(isMobile && {
          pt: 3,
          width: '100%',
        }),
      }}
    >
      <Stack
        direction={isMobile ? 'row' : 'column'}
        justifyContent="center"
        spacing={1.5}
      >
        {footerMenusTranslated.map(item => (
          <Item key={item.id}>
            <a
              href={item.url}
              data-cy={item?.id}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                analyticsEvent({
                  category: 'Footer',
                  action: 'Apps',
                  label: item.id,
                })
              }
              title={item.title}
              aria-label={item.title}
            >
              {item.icon}
            </a>
          </Item>
        ))}
      </Stack>
    </Box>
  );
};

export default MenuApplications;
