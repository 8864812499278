import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Dialog from '@/components/molecules/Dialog/Dialog';
import { useTranslation } from '@/src/i18n';

// TODO: handle this directly in useTemporaryAppointment
// we could implement a modal manager
const Referral = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [openReferralModal, setOpenReferralModal] = React.useState(false);

  useEffect(() => {
    const { referralAppointmentWithAccount, ...query } = router.query;
    if (referralAppointmentWithAccount === 'true') {
      setOpenReferralModal(true);
      router.replace({ pathname: router.route, query });
    }
  }, [router.query]);

  return (
    <Dialog
      open={openReferralModal}
      title={t('referral-appointment-confirmed')}
      onClose={() => setOpenReferralModal(false)}
    >
      <DialogContent>
        <Typography>{t('referral-appointment-confirmed-message')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ px: 2, minWidth: 0 }}
          color="primary"
          variant="outlined"
          onClick={() => {
            setOpenReferralModal(false);
          }}
        >
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default Referral;
