import * as React from 'react';

const docavenueHosts = ['docavenue'];
const DocavenueDetector = () => {
  React.useEffect(() => {
    if (document.referrer) {
      const url = new URL(document.referrer);
      for (const docavenueHost of docavenueHosts) {
        if (url.hostname.includes(docavenueHost)) {
          window.sessionStorage.setItem('fromDocavenue', 'true');
        }
      }
    }
  }, []);
  return null;
};

export default DocavenueDetector;
