import {
  MERGE_TEMPORARY_APPOINTMENT,
  SET_TEMPORARY_APPOINTMENT,
  RESET_TEMPORARY_APPOINTMENT,
} from '../actions/temporaryAppointments';

const initialState = {
  item: {},
};
const temporaryAppointments = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEMPORARY_APPOINTMENT:
      return {
        ...state,
        item: {
          ...action.payload.item,
        },
      };
    case MERGE_TEMPORARY_APPOINTMENT:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload.item,
        },
      };
    case RESET_TEMPORARY_APPOINTMENT:
      return initialState;
    default:
      return state;
  }
};

export default temporaryAppointments;
