import React from 'react';
import { put, takeEvery, select } from 'redux-saga/effects';
import dynamic from 'next/dynamic';
import { Document } from '@maiia/model/generated/model/api-patient/api-patient';
import { enqueueSnackbar } from '../actions/snackbar';
import { updateUI } from '../actions/ui';
import { documentsActions } from '../actions';

const DocumentNotification = dynamic(
  () => import('../../components/atoms/Snackbar/DocumentNotification'),
  // eslint-disable-next-line react/display-name
  { loading: () => <div /> },
);

const DocumentNotificationWithRef = React.forwardRef<
  HTMLDivElement,
  { document: Document }
>((props, ref) => (
  <div ref={ref}>
    <DocumentNotification {...props} />
  </div>
));
DocumentNotificationWithRef.displayName = 'DocumentNotificationWithRef';

function* websocketMessageHandle(wsMessage) {
  const {
    message: { resource, action, payload },
  } = wsMessage;
  if (resource === 'document' && action === 'CREATED') {
    yield put(
      enqueueSnackbar({
        key: payload.id,
        duration: 5000,
        severity: 'info',
        children: <DocumentNotificationWithRef document={payload} />,
      }),
    );
    yield put(
      updateUI({
        showNewDocumentNotification: true,
      }),
    );
    const listDocumentQuery = yield select(
      state => state?.documents?.queries?.[0],
    );
    // Refetch list documents if user viewing all documents or received documents
    if (
      listDocumentQuery &&
      (listDocumentQuery?.sourceUserType ?? 'PRO') === 'PRO'
    ) {
      yield put(
        documentsActions.getList({
          sourceUserType: listDocumentQuery?.sourceUserType,
        }),
      );
    }
  }
}

export default function* ws() {
  yield takeEvery('@@WS/MESSAGE', websocketMessageHandle);
}
