import * as React from 'react';

import { NextSeo } from 'next-seo';
import { Typography } from '@mui/material';
import ErrorPage from '../ErrorPage/ErrorPage';
import { Trans, useTranslation } from '@/src/i18n';

const Maintenance = () => {
  const { t } = useTranslation();
  return (
    <>
      <NextSeo noindex nofollow />
      <ErrorPage
        displayMaiiaLogo
        disableHeaderHeightOffset
        title={t('maintenance-title')}
        message={
          <Typography mt={2} maxWidth={460}>
            <Trans i18nKey="maintenance-text" components={{ br: <br /> }} />
          </Typography>
        }
        displayHomeButton={false}
      />
    </>
  );
};

export default Maintenance;
