import React, { FC } from 'react';
import {
  Dialog as MuiDialog,
  DialogProps,
  DialogTitle,
  Typography,
} from '@mui/material';
import { IconCross } from '@maiia/design-system';
import { css, styled } from '@mui/material/styles';
import clsx from 'clsx';

const StyledIconCross = styled(IconCross)(
  ({ theme }) => css`
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left: ${theme.spacing(1)};
    // To disable shrinking when modal is smaller
    flex-shrink: 0;
  `,
);

type Props = DialogProps & {
  title?: string;
  'data-testid'?: string;
};

const Dialog: FC<Props> = props => {
  const { title, onClose, children, PaperProps = {}, ...rest } = props;
  const { className, ...restPaperProps } = PaperProps;

  return (
    <MuiDialog
      onClose={onClose}
      PaperProps={{
        className: clsx('disable-scss-override', className),
        ...restPaperProps,
      }}
      // data-testid will be overwritten if one is passed through the props
      data-testid="dialog"
      {...rest}
    >
      {title && (
        <DialogTitle component="div" mb={0}>
          <Typography
            variant="h5"
            color="white"
            width="100%"
            textAlign="center"
            sx={{
              mb: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            data-testid="dialog-title"
          >
            {title}
          </Typography>
          {onClose && (
            <StyledIconCross
              color="white"
              onClick={onClose}
              data-testid="close-dialog-icon"
            />
          )}
        </DialogTitle>
      )}
      {children}
    </MuiDialog>
  );
};

export default Dialog;
