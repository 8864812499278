import Grid from '@mui/material/Grid';
import * as React from 'react';
import MenuInformationsAndArticles from './MenuInformationsAndArticles';
import MenuSpecialities from './MenuSpecialities';

const SpecialitiesAndMenuInformations = ({ dynamic = false }) => (
  <Grid container columnSpacing={{ xs: 0, sm: 2 }}>
    <Grid item xs={12} sm={6}>
      <MenuSpecialities dynamic={dynamic} />
    </Grid>
    <Grid item xs={12} sm={6}>
      <MenuInformationsAndArticles />
    </Grid>
  </Grid>
);

export default SpecialitiesAndMenuInformations;
