import * as React from 'react';
import { isEmpty } from 'lodash';
import { useCurrentUser } from '@docavenue/components';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

// eslint-disable-next-line import/no-extraneous-dependencies
import dynamic from 'next/dynamic';
import { Button as MaterialButton, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Heading } from '@kiiwi/ui';
import Link from '../../atoms/Link/Link';
import Menu from '../../molecules/Menu/Menu';
import Avatar from '../../atoms/Avatar/Avatar';
import {
  isDemoAccountSelector,
  temporaryAppointmentSelector,
} from '../../../src/selectors';
import { useTranslation } from '@/src/i18n';

import { dayjsWithTimezone } from '@/src/utils';
import { APP_ROUTES, DEFAULT_TIMEZONE } from '@/src/constants';
import { temporaryAppointmentsActions } from '../../../src/actions';
import { getAvatar, getCardFullName, getSubstitute } from '../../utils';
import HomeBanner from './HomeBanner';
import { isValidTemporaryAppointment } from '@/components/utils/appointment';
import CovidBanner from '@/components/organisms/Header/CovidBanner';
import NotAuthenticated from '@/components/organisms/Header/NotAuthenticated';
import Authenticated from '@/components/organisms/Header/Authenticated';
import { Tag } from '@/components/ui/not-existing-kiiwi-components/Tag';

type Props = {
  variant?: string;
};

const SwitchLanguage = dynamic(() => import('./SwitchLanguage'), {
  ssr: false,
});

const SearchBar = dynamic(() => import('../../molecules/SearchBar/SearchBar'));

const {
  HOME,
  LOGIN,
  APPOINTMENT_CONFIRMATION,
  SPECIALITY,
  MEDICAMENTS,
  LINK_ACCOUNT,
} = APP_ROUTES;

const pagesNotShowBannerContinueScheduleAppointment = [
  APPOINTMENT_CONFIRMATION,
  SPECIALITY.LOCALITY_SLUG,
  LOGIN,
];

/* const routeEnabledCovidBanner = isLoggedIn => isLoggedIn ? [NO_RDV_SEARCH_SLUG] : [HOME, NO_RDV_SEARCH_SLUG]; // Hide covid banner on homepage if user is connected */
const routeEnabledCovidBanner: (...args: any) => string[] = () => []; // Disable completely covid banner for now
// const enableBCBCtaRoutes = (isLoggedIn: boolean) => (!isLoggedIn ? [HOME] : []); // Display BCB CTA on homepage if user is not connected
// const disabledLogoRoutes = [SPECIALITY.LOCALITY_SLUG]; // Special request for vaccination campaign
// const publicSanteHosts = ['sante', 'csb']; // csb = codesandbox for testing only

const TemporaryAppointmentBanner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const temporaryAppointment = useSelector(temporaryAppointmentSelector);
  const { substitute } = getSubstitute(temporaryAppointment);
  const fullName = getCardFullName(t, {
    ...temporaryAppointment?.cardHcd,
    substituteData: substitute,
  });
  const dayjsTZ = dayjsWithTimezone(
    temporaryAppointment?.cardHcd?.settings?.timeZone || DEFAULT_TIMEZONE,
  );
  const temporaryAppointmentStartDate = dayjsTZ(
    temporaryAppointment?.startDate,
  ).format('dddd D MMMM [à] HH[h]mm');
  const avatar = getAvatar(temporaryAppointment);
  const cancelTemporaryAppointment = React.useCallback(() => {
    dispatch(temporaryAppointmentsActions.resetItem());
  }, []);

  return (
    <div className="bandeau-action">
      <Avatar source={avatar} size="small" />
      <p className="killonmobile">
        {t('continue_schedule_appointment', {
          motif: temporaryAppointment?.consultationReason?.name,
          date: temporaryAppointmentStartDate,
          // todo(raph): change practicien to entity (poeditor & here)(hotfix for synchro)
          practicien: fullName,
        })}
      </p>
      <Stack spacing={{ xs: 1, sm: 2 }} direction={{ xs: 'column', sm: 'row' }}>
        {/* Spécific mobile  */}
        <p className="killon1023">
          {temporaryAppointment?.consultationReason?.name}
          <br />
          <span>{temporaryAppointmentStartDate}</span>
        </p>
        <Link href={APPOINTMENT_CONFIRMATION} passHref>
          <MaterialButton
            sx={{ whiteSpace: 'nowrap' }}
            variant="contained"
            color="secondary"
          >
            {t('finalize_rdv')}
          </MaterialButton>
        </Link>
        <MaterialButton
          variant="contained"
          color="primary"
          onClick={cancelTemporaryAppointment}
        >
          {t('action_cancel')}
        </MaterialButton>
      </Stack>
    </div>
  );
};

const Header = ({ variant }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const isPatientDemo = useSelector(isDemoAccountSelector);
  const temporaryAppointment = useSelector(temporaryAppointmentSelector);
  const showCovidBanner = React.useMemo(
    () =>
      routeEnabledCovidBanner(!isEmpty(currentUser)).includes(router.pathname),
    [router.pathname, currentUser],
  );
  const showBannerContinueScheduleAppointment = React.useMemo(
    () =>
      !pagesNotShowBannerContinueScheduleAppointment.includes(router.pathname),
    [router.pathname],
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const IS_HOME = router.pathname === HOME;
  const IS_BCB = router.pathname.includes(MEDICAMENTS.BASE_URL);
  const IS_LINK_ACCOUNT = router.pathname === LINK_ACCOUNT.VERIFY;

  return (
    <div className="relative flex flex-row">
      <div
        data-testid="main-header"
        className={`fixed top-0 z-[1250] h-14 w-full px-4 lg:px-6 ${
          IS_BCB ? 'bg-[#4eb3c9]' : 'bg-header-brand-surface'
        }`}
      >
        <div className="relative flex h-full items-center ">
          <div className="flex flex-1 items-center justify-between">
            <div className="flex flex-row items-center gap-x-2">
              {!isEmpty(currentUser) && <Menu />}
              {isEmpty(currentUser) && (
                <>
                  <Link href={HOME} passHref>
                    <a
                      data-cy="header-logo"
                      title={t('back-to-maiia-home')}
                      aria-label={t('back-to-maiia-home')}
                    >
                      <img
                        src="/static/images/logo-maiia-monochrome.svg"
                        className="h-8 cursor-pointer"
                        alt="Logo Maiia"
                      />
                    </a>
                  </Link>
                  {IS_BCB && (
                    <>
                      <Heading as="h6" className="text-white" variant="title-3">
                        &
                      </Heading>
                      <Link href={MEDICAMENTS.BASE_URL}>
                        <img
                          src="/static/images/logo-claude-bernard.svg"
                          className="h-10 cursor-pointer text-white"
                          alt="Logo Maiia"
                        />
                      </Link>
                    </>
                  )}
                </>
              )}
              {isPatientDemo && <Tag variant="pink">DEMO</Tag>}
            </div>

            {variant === 'with-searchbar' && !isMobile && (
              <div className="search-bar flex flex-1 justify-center px-10">
                <SearchBar allowClear />
              </div>
            )}
            {!IS_LINK_ACCOUNT && (
              <div className="flex flex-row items-center gap-2">
                {IS_HOME && !isMobile && <SwitchLanguage />}
                {isEmpty(currentUser) ? (
                  <NotAuthenticated isBcb={IS_BCB} />
                ) : (
                  <Authenticated />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {(showCovidBanner ||
        (showBannerContinueScheduleAppointment &&
          isValidTemporaryAppointment(temporaryAppointment) &&
          currentUser) ||
        IS_HOME) && (
        <div className="bg-green-500 mt-14 w-full">
          {showCovidBanner && <CovidBanner />}
          {showBannerContinueScheduleAppointment &&
            isValidTemporaryAppointment(temporaryAppointment) &&
            currentUser && <TemporaryAppointmentBanner />}
          {IS_HOME && <HomeBanner />}
        </div>
      )}
    </div>
  );
};

export default Header;
