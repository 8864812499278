/* eslint-disable global-require, import/no-extraneous-dependencies */
import * as React from 'react';
import { Trans as TransImpl } from 'next-i18next';

export { useTranslation } from 'next-i18next';

// safe Trans component
/*
When the i18n context is not available, the Trans component returns its children.
However, if children are not provided, it returns undefined, which is not a valid component and will cause an error.
To address this issue, we have set the default value of children to null.
 */
export const Trans = ({
  children = null,
  ...props
}: React.ComponentProps<typeof TransImpl>) => (
  <TransImpl {...props}>{children}</TransImpl>
);
