import { Dispatch } from 'redux';
import { asyncActions } from '@docavenue/core';
import { ResultPageAmcProtectedPatientDTO } from '@maiia/model/generated/model/api-patient/api-patient';
import {
  PropertyRecord,
  ScreebObject,
  ScreebOptions,
  Hooks,
} from '@screeb/sdk-browser'; // Do not import anything except type from this sdk, it will cause ssr crash
import { PatientUser } from '@/components/molecules/WithAuth/types';
import { usersActions } from './actions';
import { amcProtectedPatientsActions } from './hooks/useTlcAmcProtectedPatients';

type Screeb = {
  load: (options?: ScreebOptions) => Promise<undefined>;
  init: (
    websiteId: string,
    userId?: string,
    userProperties?: PropertyRecord,
    hooks?: Hooks,
  ) => void | Promise<unknown>;
  callScreebCommand: (..._: unknown[]) => void | Promise<unknown>;
  eventTrack: (
    eventName: string,
    eventProperties?: PropertyRecord,
  ) => void | Promise<unknown>;
  identity: (
    userId: string,
    userProperties?: PropertyRecord,
  ) => void | Promise<unknown>;
  identityReset: () => void | Promise<unknown>;
};

declare const window: Window & { $screeb?: ScreebObject };
const calculateAgeRange = (birthDate?: string) => {
  if (!birthDate) return 'unknown';
  const today = new Date();
  const _birthDate: Date = new Date(birthDate);

  const age: number = today.getFullYear() - _birthDate.getFullYear();
  if (age <= 0) {
    return '1-10';
  }

  if (age % 10 === 0) {
    return `${age - 9}-${age}`;
  }

  const lowerBound = Math.floor(age / 10) * 10 + 1;
  const upperBound = lowerBound + 9;

  return `${lowerBound}-${upperBound}`;
};

const props = [
  'load',
  'init',
  'callScreebCommand',
  'eventTrack',
  'identity',
  'identityReset',
];
const screeb = (new Proxy(
  {},
  {
    get: (_obj, prop: string) => {
      if (props.includes(prop)) {
        return async (...args: any[]) => {
          try {
            const _screeb = await import('@screeb/sdk-browser');
            return _screeb[prop](...args);
          } catch (e) {
            if (process.env.NODE_ENV !== 'production') {
              console.error(e);
            }
          }
        };
      }
      return _obj[prop];
    },
  },
) as unknown) as Screeb;

export const getCurrentIdenity = async (dispatch: Dispatch) => {
  const currentUser = await asyncActions<PatientUser>(
    dispatch,
    usersActions.getOne('me'),
  );
  const { userPatientProfiles } = currentUser.userPatientInformation;
  const userProfile = userPatientProfiles?.[0];
  const amcProtectedPatients = await asyncActions(
    dispatch,
    amcProtectedPatientsActions.getList(),
  ).then((res: ResultPageAmcProtectedPatientDTO) => res.items);
  const amc = amcProtectedPatients?.find(
    item => item.patientProfileId === userProfile?.id,
  );
  return {
    id: currentUser.id,
    properties: {
      role: 'patient',
      age_range: calculateAgeRange(userProfile?.birthDate),
      gender: userProfile?.sex ?? 'unknown',
      has_relatives: (userPatientProfiles?.length ?? 0) > 1,
      protected_by_amc: amc ? amc.amcName ?? '' : false,
      is_protected_by_amc_expired: amc?.expirationDate
        ? new Date(amc.expirationDate) < new Date()
        : 'unknown',
      name: currentUser.id, // A user will be considered as "identified" as soon as one of the following properties is provided: firstname, lastname, name, fullname, username and/or email. Otherwise, they'll consider them "anonymous" in their platform.
    },
  };
};

export const screebIdentity = async (dispatch: Dispatch) => {
  const currentIdentity = await getCurrentIdenity(dispatch);
  screeb.identity(currentIdentity.id, currentIdentity.properties);
};

export const screebInit = async (
  dispatch: Dispatch,
  websiteId: string,
  hooks?: Hooks,
) => {
  const currentIdentity = await getCurrentIdenity(dispatch);
  screeb.init(websiteId, currentIdentity.id, currentIdentity.properties, hooks);
};

// This queue for event triggered when screeb is not initialized
const eventsQueue: [string, PropertyRecord | undefined][] = [];

export const screebEventTrack = (
  eventName: string,
  eventProperties?: PropertyRecord,
) => {
  // Dont track if not login
  if (!window.localStorage.getItem('tokenPat')) return;
  if (window.$screebInitialized)
    return screeb.eventTrack(eventName, eventProperties);
  eventsQueue.push([eventName, eventProperties]);
};

export const screebCleanQueue = () => {
  while (eventsQueue.length > 0) {
    const [eventName, eventProperties] = eventsQueue.shift()!;
    screeb.eventTrack(eventName, eventProperties);
  }
};

export default screeb;

export const isScreebLoaded = () =>
  Boolean(window.$screeb) && typeof window.$screeb === 'function';
