export const SET_TIME_OFFSET = 'SET_TIME_OFFSET';
export const SET_PUBLIC_KEY = 'SET_PUBLIC_KEY';

const serverConfigurationActions = {
  setTimeOffset: serverTime => ({
    type: SET_TIME_OFFSET,
    payload: { serverTime },
  }),
  setPublicKey: publicKey => ({
    type: SET_PUBLIC_KEY,
    payload: { publicKey },
  }),
};

export default serverConfigurationActions;
