import React from 'react';
import { useRouter } from 'next/router';
import { APP_ROUTES } from '@/src/constants';
import DynamicFooterSpeciality from './DynamicFooterSpeciality';
import DynamicFooterDrugs from './DynamicFooterDrugs';
import SpecialitiesAndMenuInformations from '../SpecialitiesAndMenuInformations';
import DynamicFooterTlc from './DynamicFooterTlc';

const DynamicFooter = ({ pageProps }) => {
  const { pathname } = useRouter();

  // For all drugs related pages
  if (pathname.startsWith(APP_ROUTES.MEDICAMENTS.BASE_URL)) {
    return <DynamicFooterDrugs />;
  }

  // For speciality search page
  if (pageProps?.speciality && pathname === APP_ROUTES.SPECIALITY.LOCALITY) {
    return <DynamicFooterSpeciality speciality={pageProps.speciality} />;
  }

  // For TLC pages
  if (pathname.startsWith(APP_ROUTES.TLC.BASE_URL)) {
    return <DynamicFooterTlc />;
  }

  // For other pages
  return <SpecialitiesAndMenuInformations dynamic />;
};

export default DynamicFooter;
