import React from 'react';
import { DefaultSeo as NextSeoDefault } from 'next-seo';
import { useTranslation } from '@/src/i18n';
import { urlToCanonical } from '@/components/utils';
import config from '@/src/config';

const DefaultSeo = ({ baseUrl }: { baseUrl: string }) => {
  const { t } = useTranslation();
  const noindex = config.get('NO_INDEX', 'false') === 'true';

  return (
    <NextSeoDefault
      title={t('seo-title_default')}
      description={t('seo-description_default')}
      dangerouslySetAllPagesToNoIndex={noindex}
      dangerouslySetAllPagesToNoFollow={noindex}
      openGraph={{
        type: 'website',
        url: baseUrl,
        locale: 'fr_FR',
        site_name: 'Maiia',
        title: t('seo-og-title_default'),
        description: t('seo-og-description_default'),
        images: [
          {
            url: urlToCanonical('/static/images/og-image.jpg'), // we need an absolute URI for og previews to work
            width: 1200,
            height: 1200,
            alt: 'Maiia',
          },
        ],
      }}
      additionalLinkTags={[
        {
          rel: 'manifest',
          href: '/manifest.json',
        },
      ]}
      additionalMetaTags={[
        {
          name: 'apple-itunes-app',
          content:
            'app-id=1489590732, app-argument=https://apps.apple.com/app/maiia-t%C3%A9l%C3%A9consultation-rdv/id1489590732',
        },
      ]}
    />
  );
};

export default DefaultSeo;
