import * as React from 'react';
import { css, styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  NoSsr,
  Theme as MUITheme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { IconChevronDown, List } from '@maiia/design-system';
import { useTranslation } from '@/src/i18n';
import { dynamicFooterDrugs } from '../footerMenus';

/* JS styling */

const ListBold = styled(List)(
  () => css`
    li {
      font-weight: 700;
    }
  `,
);

const FooterAccordion = styled(Accordion)(
  ({ theme }) => css`
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid ${theme.palette.common.white};

    &:last-of-type {
      border-radius: 0;
    }

    .MuiButtonBase-root {
      padding-left: 0;
      padding-right: 0;

      .MuiAccordionSummary-content {
        margin-top: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(2)};

        h2 {
          margin-bottom: 0;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          width: auto;
          height: ${theme.spacing(3)};
          fill: ${theme.palette.common.white};
        }
      }
    }

    .MuiAccordionDetails-root {
      padding: 0 0 ${theme.spacing(3)} 0;

      .MuiGrid-item + .MuiGrid-item {
        padding-top: ${theme.spacing(1)};
      }

      ul {
        li {
          color: ${theme.palette.common.white};
        }
      }
    }
  `,
);

/* Component */

const DynamicFooterDrugs = () => {
  const { t } = useTranslation();
  const footerMenusTranslated = dynamicFooterDrugs(t);
  const isMobile = useMediaQuery<MUITheme>(theme =>
    theme.breakpoints.down('sm'),
  );

  const accordionTitle = (
    <Typography variant="h3" component="h2">
      {t('footer_drug-directory')}
    </Typography>
  );

  const accordionContent = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={6}>
        <ListBold listItems={footerMenusTranslated} />
      </Grid>
    </Grid>
  );

  return (
    <NoSsr>
      {isMobile ? (
        <FooterAccordion disableGutters>
          <AccordionSummary className="py-2" expandIcon={<IconChevronDown />}>
            {accordionTitle}
          </AccordionSummary>
          <AccordionDetails>{accordionContent}</AccordionDetails>
        </FooterAccordion>
      ) : (
        <>
          {accordionTitle}
          {accordionContent}
        </>
      )}
    </NoSsr>
  );
};

export default DynamicFooterDrugs;
