import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { centersTreeActions } from '../actions';

const findRootCenter = selectedCenter => {
  if (!selectedCenter.parentCenter) {
    return selectedCenter;
  }

  return findRootCenter(selectedCenter.parentCenter);
};

const useCentersTree = ({ card, cards }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!card) return;
    if (card.type === 'CENTER') {
      const { center } = card;
      const nestedRootCenter = findRootCenter(center);
      if (center.id !== nestedRootCenter.id) {
        dispatch(
          centersTreeActions.getList({
            centerId: nestedRootCenter.id,
          }),
        );
      } else {
        dispatch(
          centersTreeActions.setItems([
            {
              center,
            },
          ]),
        );
      }
    } else {
      dispatch(
        centersTreeActions.setItems(
          cards.map(({ center }) => ({
            center,
          })),
          { shouldClean: false }, // Dont clean invisible centers if this is a prat page
        ),
      );
    }
    return () => {
      dispatch(centersTreeActions.reset());
    };
  }, [card, cards]);
};

export default useCentersTree;
