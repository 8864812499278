import { NextRouter } from 'next/router';

type PROVIDERS = 'GOOGLE_TAG_MANAGER' | 'MATOMO_TAG_MANAGER';
type PageEventProps =
  | {
      event: string;
      page: string;
      pathname: string | undefined;
    }
  | {
      event: 'customPageView';
      customUrl: string;
      customTitle: string;
    };

const PROVIDER: PROVIDERS = 'MATOMO_TAG_MANAGER';

const handleKeys: Record<PROVIDERS, string> = {
  GOOGLE_TAG_MANAGER: 'dataLayer',
  MATOMO_TAG_MANAGER: '_mtm',
};

type AnalyticEvent = {
  category: string;
  action: string;
  label?: string;
  value?: string;
};

/**
 *
 * @param params
 */
export const analyticsEvent = (params: AnalyticEvent) => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line no-console
    console.trace("Don't call analyticsEvent in server side");
    return;
  }
  const { category, action, label, value } = params;
  let event: Record<string, string | undefined | null> = {
    event: 'analyticsEvent',
    category,
    action,
    label,
    value,
  };
  if (PROVIDER === 'MATOMO_TAG_MANAGER') {
    event = {
      event: 'customEvent',
      customEventCategory: category,
      customEventAction: action,
      customEventName: label,
      customEventValue: value,
    };
  }

  window[handleKeys[PROVIDER]] = window[handleKeys[PROVIDER]] || [];
  window[handleKeys[PROVIDER]].push(event);
};

const GTMPageView = (url: string, router?: NextRouter) => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line no-console
    console.trace("Don't call analyticsEvent in server side");
    return;
  }

  let pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
    pathname: router?.pathname,
  };
  if (PROVIDER === 'MATOMO_TAG_MANAGER') {
    pageEvent = {
      event: 'customPageView',
      customUrl: router?.pathname.includes('[id]') ? router.pathname : url,
      customTitle: document.title,
    };
  }
  window[handleKeys[PROVIDER]] = window[handleKeys[PROVIDER]] || [];
  window[handleKeys[PROVIDER]].push(pageEvent);
  return pageEvent;
};

const setCustomDataLayerVariable = (key: string, value: string) => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line no-console
    console.trace("Don't call setCustomDataLayerVariable in server side");
    return;
  }
  window[handleKeys[PROVIDER]] = window[handleKeys[PROVIDER]] || [];
  window[handleKeys[PROVIDER]].push({ [key]: value });
};

export const setTrackingUserId = (userId: string) =>
  setCustomDataLayerVariable('uid', userId);

export const setTrackingPartnerId = (partnerId: string) =>
  setCustomDataLayerVariable('partnerId', partnerId);

export default GTMPageView;
