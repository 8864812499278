import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from '@docavenue/components';
import { actionsGenerator, asyncActions } from '@docavenue/core';
import { PatientUser } from '@/components/molecules/WithAuth/types';
import { isCompleted } from '@/components/molecules/WithAuth/functions';
import { ProtectedPatient } from '@/components/templates/TlcAmc/utils';

export const amcProtectedPatientsActions = actionsGenerator({
  scope: 'pat',
  chunkUrlResource: 'amc-protected-patients',
});

const useTlcAmcProtectedPatients = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser<PatientUser>();
  return useQuery<{ items: ProtectedPatient[]; total: number }>({
    queryKey: ['protected-patients'],
    queryFn: () =>
      asyncActions(dispatch, amcProtectedPatientsActions.getList()),
    enabled: !!currentUser && isCompleted(currentUser, true) === true,
  });
};

export default useTlcAmcProtectedPatients;
