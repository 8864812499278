import { takeEvery } from 'redux-saga/effects';

function logoutHandle(action) {
  if (action.resource !== 'logout') return;
  localStorage.setItem('logout-event', `logout${Math.random()}`);
  localStorage.removeItem('tokenPat');
  localStorage.removeItem('refreshPat');
  if (typeof window !== 'undefined' && window.$screeb) {
    window.$screeb('close');
    delete window.$screebInitialized;
  }
  if (action.forceReload) window.location.reload();
}

export default function* logout() {
  yield takeEvery('CREATE_SUCCESS', logoutHandle);
}
