import { get } from '@docavenue/core';
import {
  CardHcd,
  Picture,
} from '@maiia/model/generated/model/api-patient/api-patient';
import { NO_PRAT_PREFER, NO_PRAT_ONLINE, CENTER } from '../../src/constants';

export const getSector = (sector: string | undefined) => {
  if (!sector) return '';
  const sectors = {
    SECTOR1: 'convention_sector_1',
    SECTOR2: 'convention_sector_2',
    SECTOR3: 'convention_sector_3',
    SECTOR1_DP_OPTAM: 'convention_sector_1_dp_optam',
    SECTOR2_OPTAM: 'convention_sector_2_optam',
    SECTOR2_OPTAM_CO: 'convention_sector_2_optam_co',
    AFFILIATED: 'convention_sector_affiliated',
    NOT_AFFILIATED: 'convention_sector_not_affiliated',
  };

  return sectors[sector];
};

export const getAddress = item => {
  if (!get(item, 'publicInformation')) {
    return '';
  }
  const {
    publicInformation: {
      address: {
        number = '',
        streetType = '',
        street = '',
        zipCode = '',
        city = '',
      },
    },
  } = item;

  return `${number} ${streetType} ${street} ${zipCode} ${city}`;
};

export const getNumberAndStreetName = item => {
  if (!item?.publicInformation?.address) {
    return '';
  }

  const {
    publicInformation: {
      address: { number = '', street = '', streetType = '' },
    },
  } = item;

  return `${number} ${streetType} ${street}`;
};

export const getPostalCodeAndCity = item => {
  if (!get(item, 'publicInformation.address')) {
    return '';
  }

  const {
    publicInformation: {
      address: { zipCode = '', city = '' },
    },
  } = item;

  return `${zipCode}, ${city}`;
};

export const getCity = (item: CardHcd | undefined): string =>
  item?.publicInformation?.address?.city ?? '';

export const getSpecialityName = item =>
  item?.type !== 'CENTER'
    ? get(item, 'professional.speciality.name')
    : get(item, 'center.speciality.name');

export const getCenterSpecialityName = item =>
  get(item, 'center.speciality.name');

export const getCenterName = item => get(item, 'center.name');

export const getPicture = (s3Id?: string) => {
  if (!s3Id) {
    return;
  }
  return `/files/${s3Id}`;
};

export const getPictureSource = (
  card?:
    | { mainPicture?: Picture }
    | { publicInformation?: { mainPicture?: Picture } },
) =>
  (card as { mainPicture?: Picture })?.mainPicture
    ? getPicture(
        get(card as { mainPicture?: Picture }, 'mainPicture.thumbnailS3Id'),
      )
    : getPicture(
        get(
          card as { publicInformation?: { mainPicture?: Picture } },
          'publicInformation.mainPicture.thumbnailS3Id',
        ),
      );

export const getCardMainPicture = (card: CardHcd, pictureType?: any) => {
  const avatars = {
    CENTER: '/static/images/default_center.svg',
    PRACTITIONER: '/static/images/default_practitioner.svg',
    UNKNOWN: '/static/images/default_practitioner.svg',
    PHARMACY: '/static/images/default_center.svg',
  };
  const s3Id = card?.publicInformation?.mainPicture?.s3Id;
  if (s3Id) return getPicture(s3Id);
  const type = card?.type ?? pictureType;
  return avatars?.[type] ?? '/static/images/default_practitioner.svg';
};

export const hasInformation = (card: CardHcd) =>
  card?.publicInformation?.officeDescription ||
  card?.publicInformation?.spokenLanguages?.[0] ||
  card?.publicInformation?.curriculums?.[0] ||
  card.publicInformation?.externalLink;

export const hasVisibleAddress = (card: CardHcd) =>
  // Will show address by default if it's not explicitly hidden
  card?.publicInformation?.isAddressVisible !== false;

export const hasVisibleAddressInChildren = (card: CardHcd) => {
  const centers = card.center?.childCenters || [];
  for (const center of centers) {
    if (hasVisibleAddress(center)) return true;
  }
  return false;
};

export const hasAnyVisibleAddress = (card: CardHcd) =>
  hasVisibleAddress(card) || hasVisibleAddressInChildren(card);

export const isTypeCenter = (card: CardHcd) => card?.type === 'CENTER';

export const cardIsFavoritePractitionerOfProfile = (card, profile) =>
  profile.favoritePractitioners?.some(
    (favoritePractitioner: any) =>
      card?.professional?.practitionerId ===
        favoritePractitioner?.practitionerId &&
      card?.center?.id === favoritePractitioner?.centerId,
  );

export const cardIsFavoriteCenterOfProfile = (card, profile) =>
  profile.favoriteCenters?.some(
    (favoriteCenter: any) =>
      Boolean(favoriteCenter?.centerId) &&
      card?.type === CENTER &&
      card?.center?.id === favoriteCenter?.centerId,
  );

export const cardIsReferringPractitionerOfProfile = (card, profile) =>
  Boolean(card?.professional?.cpsId) &&
  card?.center?.id === profile.referringPractitioner?.centerId &&
  card?.professional?.cpsId === profile.referringPractitioner?.cpsId;

export const hasOnlyTlcOffer = (card: CardHcd) =>
  card?.settings?.isTeleconsultation && !card?.settings?.isOnlineAppointment;

export const isNoPractitionerSelected = (
  practitionerId: string | undefined,
): boolean =>
  practitionerId === NO_PRAT_PREFER || practitionerId === NO_PRAT_ONLINE;

export const getCardExpertiseTag = (
  card: CardHcd,
  searchObject: any,
): string | undefined => {
  const expertise = card.publicInformation?.expertises?.find(
    x => x.id === searchObject?.id,
  );
  const expertiseSynonym = card.publicInformation?.expertises?.find(x =>
    x.synonyms?.find(s => s?.id === searchObject?.id),
  );
  return expertise?.name ?? expertiseSynonym?.name;
};

export const getCardSpecialityTag = (
  card: CardHcd,
  searchObject: any,
): string | undefined => {
  if (
    card.type === 'CENTER' &&
    card?.center?.speciality?.name !== searchObject?.name
  ) {
    const nbrPractitioners =
      card.center?.practitioners?.filter(
        (p: any) => p?.speciality?.name === searchObject?.name,
      ).length || 0;
    if (nbrPractitioners > 0)
      return `${searchObject?.name} (${nbrPractitioners})`;
  }
};

export const isRdvEnabled = (card: CardHcd): boolean => {
  if ((card.consultationReasons ?? []).length === 0) return false;
  if (isTypeCenter(card)) return true;
  return (
    (card.settings?.isOnlineAppointment ?? false) ||
    (card.settings?.isTeleconsultation ?? false)
  );
};

export const isDocavenueClient = (card: CardHcd): boolean =>
  card.settings?.clientDocavenue ?? false;

export const fixDepartmentCode = (code: string) =>
  code === '75' ? '75000' : code;

// Has tarifs (prices list) if defined and has at least one defined in the array
// returned
export const hasTarif = (card: CardHcd) => {
  const pricing = card?.publicInformation?.pricing;
  return pricing && pricing?.length > 0;
};
