import React, { PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

type Props = PropsWithChildren<{
  disableHeaderHeightOffset?: boolean;
  sx?: SxProps<Theme>;
}>;

const FullscreenContent = ({
  disableHeaderHeightOffset,
  sx,
  children,
}: Props) => {
  return (
    <Box
      sx={[
        theme => ({
          width: '100%',
          height: `calc(100vh - ${
            disableHeaderHeightOffset ? 0 : theme.headerHeight
          }px)`,
          marginTop: `${disableHeaderHeightOffset ? 0 : theme.headerHeight}px`,
          backgroundColor: theme.palette.background.default,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};

export default FullscreenContent;
