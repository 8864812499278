import { takeEvery, put } from 'redux-saga/effects';

function* clearSession(action) {
  const { reason } = action.payload;
  // eslint-disable-next-line no-console
  console.log('Clear current session because', reason);
  yield put({ type: 'CREATE_SUCCESS', resource: 'logout', forceReload: true });
}
export default function* watchInClearSession() {
  yield takeEvery('CLEAR_SESSION', clearSession);
}
