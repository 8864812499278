export const UPDATE_UI = 'UPDATE_UI';
export const SHOW_HOME_BANNER = 'SHOW_HOME_BANNER';
export const REMOVE_HOME_BANNER = 'REMOVE_HOME_BANNER';

type BannerPayload = {
  bannerType: 'MOVED_APPOINMENT_SUCCESS';
  params: Record<string, any>;
};

export const updateUI = (payload: any) => ({
  type: UPDATE_UI,
  payload,
});

export const showHomeBanner = (payload: BannerPayload) => ({
  type: SHOW_HOME_BANNER,
  payload,
});

export const removeHomeBanner = () => ({
  type: REMOVE_HOME_BANNER,
});
