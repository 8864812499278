import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cn, twVariants } from '@kiiwi/ui';
import { VariantProps } from '@/components/ui/not-existing-kiiwi-components/utils';

const baseButtonStyles = twVariants({
  slots: {
    root: [
      'inline-flex items-center justify-center font-bold outline-none transition-all',
      'active:scale-95',
      'disabled:scale-100 disabled:cursor-not-allowed disabled:select-none',
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-radiant-blue focus-visible:ring-offset-2',
    ],
  },
  variants: {
    variant: {
      macro: {
        root: [
          'bg-macro-enable text-action-inverse',
          'disabled:bg-macro-disable',
          'hover:bg-macro-hover',
          'active:bg-macro-hover',
        ],
      },
      primary: {
        root: [
          'bg-primary-enable text-action-inverse',
          'disabled:bg-primary-disable',
          'hover:bg-primary-hover',
          'active:bg-primary-hover',
        ],
      },
      'primary-inverse': {
        root: [
          'bg-surface-white text-action-active',
          'disabled:bg-surface-white disabled:text-action-disable disabled:opacity-50',
          'hover:bg-ghost-hover',
          'active:bg-ghost-active',
        ],
      },
      secondary: {
        root: [
          'border border-secondary-enable bg-transparent text-action-secondary-enable',
          'disabled:border-secondary-disable disabled:bg-transparent disabled:text-action-secondary-disable',
          'hover:bg-secondary-hover',
          'active:bg-secondary-hover',
        ],
      },
      'secondary-inverse': {
        root: [
          'border border-global-inverse bg-transparent text-action-inverse',
          'disabled:bg-transparent disabled:text-action-inverse disabled:opacity-50',
          'hover:bg-surface-white hover:text-action-enable',
          'action:bg-surface-white action:text-action-enable',
        ],
      },
      ghost: {
        root: [
          'bg-transparent text-action-enable',
          'disabled:bg-transparent disabled:text-action-disable',
          'hover:bg-ghost-hover',
          'active:bg-ghost-hover',
        ],
      },
      'ghost-delete': {
        root: [
          'bg-transparent text-alert-dark-error',
          'disabled:bg-transparent disabled:text-alert-disable-error',
          'hover:bg-error-hover',
          'active:bg-error-hover',
        ],
      },
      error: {
        root: [
          'border border-alert-error bg-transparent text-alert-dark-error',
          'hover:bg-error-hover',
          'active:bg-error-hover',
          'disabled:bg-transparent disabled:opacity-50',
        ],
      },
      warning: {
        root: [
          'border border-alert-warning bg-transparent text-alert-dark-warning',
          'hover:bg-warning-hover',
          'active:bg-warning-hover',
          'disabled:bg-transparent disabled:opacity-50',
        ],
      },
      success: {
        root: [
          'border border-alert-success bg-transparent text-alert-dark-success',
          'hover:bg-success-hover',
          'active:bg-success-hover',
          'disabled:bg-transparent disabled:opacity-50',
        ],
      },
      info: {
        root: [
          'border border-alert-info bg-transparent text-alert-dark-info',
          'hover:bg-info-hover',
          'active:bg-info-hover',
          'disabled:bg-transparent disabled:opacity-50',
        ],
      },
    },
  },
});

const buttonStyles = twVariants({
  extend: baseButtonStyles,
  slots: {
    root: [
      'inline-flex w-auto items-center justify-center whitespace-nowrap rounded-full font-semibold outline-none transition-all',
    ],
  },
  variants: {
    size: {
      sm: {
        root: 'h-8 px-4 text-body-2 [&_svg]:h-4 [&_svg]:w-4',
      },
      md: {
        root: 'h-10 px-5 [&_svg]:h-5 [&_svg]:w-5',
      },
      lg: {
        root: 'h-13 px-6 [&_svg]:h-6 [&_svg]:w-6',
      },
    },
  },
});

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonStyles> & {
    /**
     * Change the default rendered element for the one passed as a child, merging their props and behavior.
     * @default false
     */
    asChild?: boolean;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'primary',
      size = 'md',
      asChild = false,
      type = 'button',
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    const { root } = buttonStyles({ variant, size });

    return (
      <Comp className={cn(root(), className)} ref={ref} type={type} {...props}>
        {children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonStyles };
