import React from 'react';
import { styled, css } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { IconChevronDown, List } from '@maiia/design-system';
import { useTranslation } from '@/src/i18n';
import { dynamicFooterArticlesList } from './utils';

const ListBold = styled(List)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(3)};

    li {
      font-weight: 700;

      &.with-disc {
        font-weight: 400;
        list-style-type: disc;

        margin-left: ${theme.spacing(3)};
      }
    }

    // Add "margin-top" only if previous article has childArticles
    .with-disc + li:not(.with-disc) {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

const FooterAccordion = styled(Accordion)(
  ({ theme }) => css`
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid ${theme.palette.common.white};

    &:last-of-type {
      border-radius: 0;
    }

    .MuiButtonBase-root {
      padding-left: 0;
      padding-right: 0;

      .MuiAccordionSummary-content {
        margin-top: ${theme.spacing(2)};
        margin-bottom: ${theme.spacing(2)};

        h2 {
          margin-bottom: 0;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          width: auto;
          height: ${theme.spacing(3)};
          fill: ${theme.palette.common.white};
        }
      }
    }
    .MuiAccordionDetails-root {
      padding: 0 0 ${theme.spacing(3)} 0;

      .MuiGrid-item + .MuiGrid-item {
        padding-top: ${theme.spacing(1)};
      }

      ul {
        li {
          color: ${theme.palette.common.white};
        }
      }
    }
  `,
);

const ArticlesColumn = ({
  indexArticle,
  speciality,
  isMobile,
}: {
  indexArticle: ArticleDTO;
  speciality: any;
  isMobile: boolean;
}) => {
  const { t } = useTranslation();

  const accordionArticlesTitle = (
    <Typography variant="h3" component="h2">
      {t('footer_informations_and_articles')}
    </Typography>
  );

  const accordionArticlesContent = (
    <Grid container spacing={3} paddingTop={3}>
      <Grid item xs={12}>
        <List
          listItems={[
            {
              title: t('all-our-articles-on-speciality', {
                speciality: speciality.label?.toLowerCase(),
              }),
              url: `/${speciality.shortName}/article`,
            },
          ]}
        />
        <ListBold
          listItems={dynamicFooterArticlesList(indexArticle, !isMobile)}
        />
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <FooterAccordion disableGutters>
      <AccordionSummary expandIcon={<IconChevronDown />}>
        {accordionArticlesTitle}
      </AccordionSummary>
      <AccordionDetails>{accordionArticlesContent}</AccordionDetails>
    </FooterAccordion>
  ) : (
    <Grid item sm={12} md={6}>
      {accordionArticlesTitle}
      {accordionArticlesContent}
    </Grid>
  );
};

export default ArticlesColumn;
